{
  "accessDenied": {
    "description": "You don't have required permissions to view this page.",
    "title": "Access denied"
  },
  "accessories": {
    "selectPlaceholder": "Choose accessories"
  },
  "action": {
    "activate": "Activate",
    "activateOrDeactivate": "Activate/Deactivate",
    "add": "Add",
    "addNewTranslation": "Add new translation",
    "addToCollection": "Add to recipe book",
    "apply": "Apply",
    "applyFilters": "Apply filters",
    "approve": "Approve",
    "approveAll": "Approve all",
    "approveTranslations": "Approve translations",
    "assignUnassignToMyCountryProfile": "Assign/Unassign to my Country Profile",
    "blockConsumer": "Block consumer",
    "change": "Change",
    "changeIngredients": "Change ingredients",
    "checkFaq": "Check FAQ",
    "checkManuals": "Check manuals",
    "clear": "Clear",
    "deactivate": "Deactivate",
    "deflag": "Deflag",
    "delete": "Delete",
    "deleteSet": "Delete set",
    "edit": "Edit",
    "export": "Export",
    "exportToCsv": "Export to CSV",
    "exportTranslations": "Export translations",
    "flagComment": "Report",
    "flagContent": "Flag {{ content }}",
    "generateAiTranslation": "AI generate translations",
    "hideFilters": "Hide filters",
    "hideLog": "Hide log",
    "hidePreparedMeals": "Hide images",
    "like": "Like",
    "lock": "Lock",
    "moveTo": "Move to",
    "moveToDraft": "Move to draft",
    "moveToGlobal": "Move to GLOBAL",
    "moveToLive": "Move to live",
    "pin": "Pin",
    "pinUnpin": "Pin/Unpin",
    "publish": "Publish",
    "publishContent": "Publish content",
    "publishUnpublish": "Publish/Unpublish",
    "quarantine": "Quarantine",
    "reactivate": "Reactivate",
    "reassign": "Reassign",
    "reject": "Reject",
    "rejectRequest": "Reject request",
    "remove": "Remove",
    "replace": "Replace",
    "report": "Report",
    "requestEdit": "Request edit",
    "requestEditAndTakeOffline": "Request edit and take offline",
    "requestReview": "Request review",
    "revokeReporting": "Revoke report",
    "saveAndApprove": "Save and approve",
    "saveAnyway": "Save anyway",
    "saveDraft": "Save draft",
    "saveSet": "Save set",
    "schedule": "Schedule",
    "scheduleOrPublish": "Schedule/publish",
    "search": "Search",
    "selectFile": "Select file",
    "send": "Send",
    "showFilters": "Show filters",
    "showLog": "Show log",
    "showPreparedMeals": "Show images",
    "takeOffline": "Take offline",
    "translate": "Translate",
    "unapprove": "Unapprove",
    "unlike": "Unlike",
    "unlock": "Unlock",
    "unpin": "Unpin",
    "unschedule": "Unschedule",
    "updateBrands": "Update brands"
  },
  "activityStatus": {
    "active": "Active",
    "inactive": "Inactive"
  },
  "administrator": {
    "role": {
      "errors": {
        "required": "At least one user role is required"
      }
    }
  },
  "aiTranslationJobScope": {
    "all": "Regenerate all existing translations",
    "missingAndAI": "Regenerate only AI generated translations",
    "missingOnly": "Don’t regenerate existing translations"
  },
  "aiTranslationModal": {
    "description": "AI translation generation may take some time. Once the the AI finishes the translation process, translations will be created/updated.",
    "regenerateDescription": "Do you want to regenerate existing translations for the languages you have selected?",
    "title": "AI translation generation",
    "languagesDescription": "Select languages you want to translate to:"
  },
  "allergen": {
    "celery": "Celery",
    "cerealsContainingGluten": "Cereals containing gluten",
    "crustaceans": "Crustaceans",
    "eggs": "Eggs",
    "fish": "Fish",
    "lupin": "Lupin",
    "milk": "Milk",
    "molluscs": "Molluscs",
    "mustard": "Mustard",
    "noAllergens": "There are no allergens for this ingredient",
    "noUnofficialAllergens": "There are no other allergens for this recipes.",
    "nuts": "Nuts",
    "peanuts": "Peanuts",
    "placeholder": "Select allergens",
    "sectionTitle": "Food sensitivities and intolerances",
    "sesameSeeds": "Sesame Seeds",
    "soybeans": "Soybeans",
    "sulphurDioxideAndSulphites": "Sulphur dioxide and suplhites",
    "title": "Allergens",
    "placeholderOther": "Select other allergens",
    "titleOther": "Other allergens"
  },
  "announcement": {
    "browse": {
      "title": "Browse announcements"
    },
    "buttonText": "Button text",
    "code": "Code",
    "collectionID": "Recipe book ID",
    "collectionTitle": "Recipe book title",
    "content": "Content",
    "create": "Create announcement",
    "details": "Announcement details",
    "edit": "Edit announcement",
    "enterButtonText": "Enter button text",
    "enterCode": "Enter code",
    "enterDescription": "Enter announcement description",
    "enterLink": "Enter link",
    "enterTitle": "Enter announcement title",
    "errors": {
      "domain": {
        "required": "Domain is required"
      },
      "generic": {
        "description": "Error is displayed in the console. Please contact developers.",
        "title": "Error while saving the announcement"
      },
      "imageRequired": "Image is required",
      "language": {
        "required": "Language is required"
      },
      "user": {
        "required": "Profile is required"
      }
    },
    "hoverForPreview": "Hover for 9:16 preview",
    "imagePreview": {
      "fullScreen": {
        "message": "Mobile announcements full screen view (16:9) is generated automatically."
      },
      "homeScreen": {
        "message": "Mobile announcements home screen view (16:9) is generated automatically."
      }
    },
    "noResultsTitle": "No announcement found for  '{{ query }}'",
    "recipeID": "Recipe ID",
    "recipeTitle": "Recipe title",
    "replaceImage": {
      "description": "Do you want to replace current announcement image with image from this {{ contentType }}?",
      "imageResolutionWarning": "Warning: Image resolution is lower than {{ imageResolution }} px which is recommended to get a better quality of announcement image.",
      "title": "Replace announcement image"
    },
    "save": "Save",
    "saveTheAnnouncement": "Save the announcement?",
    "savedToastMessage": "Announcement saved",
    "searchUsers": "Search profiles",
    "tipID": "Article ID",
    "tipTitle": "Article title",
    "title": "Announcement title",
    "translationErrors": {
      "buttonTextRequired": "Announcement button text is required",
      "descriptionRequired": "Announcement description is required",
      "titleRequired": "Announcement title is required"
    },
    "type": "Announcement type",
    "uploadImage": "Upload image",
    "userInfoMessage": "In order to find consumer’s profile, you must enter the consumer’s full email or phone number. If you want to link a Chef, you can find it by entering Chef’s name.",
    "view": "View announcement",
    "wantToSaveBeforeLeaving": "Do you want to save the changes before leaving?"
  },
  "announcementImageUpload": {
    "validationMessage": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px."
  },
  "announcementType": {
    "collection": "Recipe book",
    "none": "General",
    "outgoing": "Link",
    "profile": "Profile",
    "recipe": "Recipe",
    "specialOffers": "Special offers",
    "store": "Store",
    "tip": "Article",
    "user": "Profile"
  },
  "approveTranslationsDialog": {
    "comment": {
      "placeholder": "Write a comment here."
    },
    "content": "You are about to approve all translations that are not approved for the language you select. Please enter your reason:"
  },
  "article": {
    "errors": {
      "missingTranslation": "Missing article step translation"
    },
    "step": {
      "delete": {
        "content": "Are you sure you want to delete the step?",
        "title": "Delete step"
      }
    }
  },
  "articlePublicationModal": {
    "missingArticleLiveTranslationsForCountry": "One or more linked articles are missing translations.",
    "missingPublishedArticles": "{{ publishedArticles }}/{{ totalArticles }} articles linked to the article have been published in the country profile. Unpublished articles will not be visible within the article."
  },
  "authorType": {
    "consumer": {
      "label": "User generated",
      "shortLabel": "C"
    },
    "homeIdTeam": {
      "label": "HomeID Team managed",
      "shortLabel": "H"
    },
    "philips": {
      "label": "Philips managed",
      "shortLabel": "P"
    }
  },
  "breakdownTable": {
    "breakdown": "Breakdown",
    "per100g": "per 100 g",
    "per100ml": "Per 100 ml",
    "percentDailyValue": "% NRV*",
    "percentDailyValuePer100g": "% NRV per 100 g *",
    "percentDailyValuePer100ml": "% NRV per 100 ml *",
    "percentDailyValuePerServing": "% NRV per serving *"
  },
  "bulkActions": {
    "contentList": {
      "choose": "Choose a content list",
      "contentMustBeApprovedForMoveToLive": "Some of the content must be in Approved status to enable the Move to live action",
      "contentMustBeLiveToApproveTranslations": "All content must be in Live or Live flagged status to approve translations",
      "contentMustBeLiveToExportTranslations": "All content must be in Live or Live flagged status to export translations",
      "contentMustBeLiveToPublish": "All content must be in Live or Live flagged status to publish",
      "contentTypeEmptyState": {
        "description": "There are no results for this content type.",
        "title": "No content found"
      },
      "description": "Select a content list and choose an action below in the footer bar menu.",
      "discardEdit": {
        "content": "Are you sure you want to leave the edit form and discard the changes you made? "
      },
      "emptyListLabel": "Empty list",
      "header": {
        "actions": "Actions",
        "content": "Content"
      },
      "label": "Content list",
      "notSelectedEmptyState": {
        "description": "Select a list from the content list to preview the content.",
        "title": "No content list"
      },
      "saved": "Content list is saved",
      "selectedStatusEmptyState": {
        "description": "There are no results for this status type.",
        "title": "No content found"
      },
      "contentMustBeLiveOrApprovedToAIGenerateTranslations": "All content must be in Approved, Live, or Live flagged status to AI generate translations"
    },
    "contentListPublishedToastMessage": "Content list published successfully",
    "contentLists": {
      "shortIdsPlaceholder": "Paste the IDs here."
    },
    "contentPreview": "Content preview ({{ contentCount }})",
    "filterSetup": {
      "description": "Get a preview of selected filters and choose an action below in the footer bar menu.",
      "noContentEmptyState": {
        "description": "There are no results for this filter setup.",
        "title": "No content found"
      },
      "notSelectedEmptyState": {
        "description": "Select and apply filters to view the content list.",
        "title": "No content list"
      }
    },
    "history": {
      "title": "Action history log"
    },
    "moveToLive": {
      "allMoved": "All content moved to Live",
      "confirmationText": "Are you sure you wish to move {{ count }} item(s) to live?",
      "errorList": "Could not move the following items to live: <br /> {{ items }}",
      "errorTitle": "Error when moving items to live",
      "someMoved": "{{ moved }} of {{ total }} item(s) successfully moved to live."
    },
    "publishingResults": {
      "description": "Bulk publishing could not be performed because of problems related to some content. Please correct the issues listed below and try again.",
      "title": "Bulk publishing failed"
    },
    "selectionType": {
      "contentLists": "Content lists"
    },
    "translationsApprovedToastMessage": "Translations approved successfully",
    "publishContentDialog": {
      "onlyDefaultCountryProfilesSupported": "Only the default country profiles are supported when Highlights are present in the list for publishing."
    }
  },
  "button": {
    "add": "Add",
    "back": "Back",
    "cancel": "Cancel",
    "close": "Close",
    "collapse": "Collapse",
    "deflag": "Deflag",
    "delete": "Delete",
    "discard": "Discard",
    "edit": "Edit",
    "generateTranslations": "Generate translations",
    "hide": "Hide {{ label }}",
    "no": "No",
    "ok": "OK",
    "post": "Post",
    "preview": "Preview",
    "previewVideo": "Preview video",
    "resolve": "Resolve",
    "save": "Save",
    "saveChanges": "Save changes",
    "search": {
      "clear": "Clear search",
      "search": "Search",
      "searchItems": "Search {{ items }}"
    },
    "show": "Show {{ label }}",
    "stay": "Stay",
    "unresolve": "Unresolve",
    "yes": "Yes",
    "yesChange": "Yes, change",
    "yesDelete": "Yes, delete",
    "leave": "Leave"
  },
  "calendar": {
    "view": {
      "day": "Day",
      "month": "Month",
      "week": "Week"
    }
  },
  "category": {
    "placeholder": "Choose {{categoryName}}"
  },
  "changelog": {
    "header": {
      "subtitle": "Lists of changes for each release of HomeID Administrative portal",
      "title": "Release notes"
    },
    "notes": {
      "hide": "Hide notes",
      "show": "Show notes"
    },
    "subtitle": {
      "defects": "Bug fixes",
      "maintenanceStories": "Maintenance",
      "notes": "Notes",
      "userStories": "Features"
    }
  },
  "claimLimitType": {
    "max": "Upper",
    "min": "Lower"
  },
  "collection": {
    "addBasicInformation": "Add basic information",
    "addRecipe": "Add",
    "addRecipesToCollection": "Add recipes to the recipe book",
    "addedRecipesToCollection": "Added recipes to the recipe book  ({{ currentValue }})",
    "changeStatus": {
      "approval": {
        "content": "You are about to approve this recipe book. Please enter your approval reason:",
        "title": "Recipe book approval"
      },
      "deflag": {
        "content": "You are about to deflag this recipe book and move it back to Live. Please enter your reason:",
        "title": "Deflagging recipe book"
      },
      "deletion": {
        "content": "Are you sure you want to delete a recipe book?",
        "title": "Recipe book deletion"
      },
      "generic": {
        "content": "You are about to change the recipe book status. Please enter your reason:",
        "title": "Changing recipe book status"
      },
      "inReview": {
        "content": "You are about to move recipe book in review. Please enter your reason:",
        "title": "Move to in review"
      },
      "moveToDraft": {
        "content": "You are about to move this recipe book to draft. Please enter your reason:",
        "title": "Recipe book draft"
      },
      "moveToLive": {
        "content": "You are about to move this recipe book to live. Please enter your reason:",
        "title": "Moving the recipe book to Live"
      },
      "rejectRequest": {
        "content": "You are about to reject this request. Please enter your rejection reason:",
        "title": "Reject approving request"
      },
      "takeOffline": {
        "content": "You are about to unpublish the recipe book from all of the country profiles. Please enter your reason:",
        "title": "Taking the recipe book offline"
      }
    },
    "create": "Create {{device}} recipe book",
    "createUniversal": "Create recipe book",
    "description": "Description",
    "details": "Recipe book details",
    "edit": "Edit {{device}} recipe book",
    "editRecipeBook": "Edit recipe book",
    "editRecipeList": {
      "label": "Edit recipe list"
    },
    "enterDescription": "Enter recipe book description",
    "enterTitle": "Enter recipe book title",
    "hoverForPreview": "Hover for 3:4 preview",
    "id": "Recipe book ID",
    "imageUploadPreview": {
      "oneByOneRatioMessage": "Recipe book cover view (1:1) on Favorites screen is generated automatically",
      "threeByFourRatioMessage": "Recipe book cover view (3:4) on Home screen is generated automatically"
    },
    "incompatibleRecipes": {
      "proceedLabel": "Remove them from the recipe book",
      "removed": "Recipes successfully removed from the recipe book.",
      "title": "The following recipes in the recipe book are not compatible with the recipe book."
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the recipe book now?"
    },
    "noRecipesAddedToCollection": "There are no recipes added to this recipe book.",
    "noRecipesFound": "No recipes found",
    "notFound": "Recipe book not found",
    "placeholder": {
      "noRecipes": "Recipe book does not have any recipes",
      "selectApplianceAndProductFirst": "Select appliance and at least one device or accessory first."
    },
    "productChangeInfoMessage": "Recipes will be reset upon changing appliance, device, or accessory.",
    "reassignLanguage": {
      "description": "Choose a supported language of the recipe book's country.",
      "toastMessage": "Recipe book language was changed to {{ language }}"
    },
    "recipeNotValid": {
      "defaultError": {
        "description": "The recipe and the recipe book do not match."
      },
      "noMatchingDomains": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have any matching domains. The recipe must be global or have the same domain as the recipe book."
      },
      "translationNotApproved": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have any approved translations in common.",
        "title": "Recipe is not valid for the recipe book"
      },
      "wrongContentStatus": {
        "description": "Recipe {{recipeTitle}} and the recipe book do not have matching appliances."
      }
    },
    "recipeRemovalDescription": "Are you sure you want to remove the recipe from the recipe book?",
    "recipeRemovalTitle": "Remove from recipe book",
    "recipeSearchDisabledMessage": "Select domain, language and appliance first",
    "recipeWarning": {
      "missingTranslations": "Recipe does not have approved translations for: {{ languages }}",
      "notPublished": "Recipe is not published to: {{ countryProfiles }}"
    },
    "save": "Save",
    "saveTheCollection": "Save the recipe book?",
    "searchAddedRecipes": "Search added recipes",
    "searchRecipes": "Search recipes",
    "status": {
      "toast": "Recipe book status has been successfully changed to \"{{ status}}\""
    },
    "title": "Recipe book title",
    "toastMessage": {
      "saved": "Recipe book saved",
      "trasnlationSaved": "Recipe book translation saved successfully."
    },
    "translationErrors": {
      "descriptionRequired": "Recipe book description is required.",
      "titleRequired": "Recipe book title is required."
    },
    "validators": {
      "recipesRequired": "Recipe book must have at least one recipe."
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book information you entered. Do you want to save the recipe book to continue editing later?"
  },
  "collectionBundle": {
    "contentCategory": {
      "choose": "Choose appliance",
      "singular": "Appliance"
    },
    "searchCollections": "Search recipe books ",
    "selectApplianceFirst": "Select appliance first"
  },
  "collectionPack": {
    "activation": {
      "contentWillBeDeactivated": "<div>You are activating recipe book pack for {{ country }}, therefore, {{ collectionBundlesForDeactivation }} will be deactivated.</div>",
      "missingRecipeBooksPerDeviceCategory": "At least {{ minRecipeBooks }} recipe books per appliance must be published to the country."
    },
    "addBasicInformation": "Add basic information",
    "addCollectionsToPack": "Add recipe books to the pack (min.{{ minCollections }})",
    "addedCollectionsToPack": "Added recipe books to the pack ({{ currentValue }})",
    "collectionMustBeLiveModal": {
      "description": "Recipe book \"{{ collectionTitle }}\" is not LIVE. Remove it from the pack or move it to LIVE before saving this pack.",
      "title": "All recipe books must be in LIVE status"
    },
    "collectionRemovalDescription": "Are you sure you want to remove the recipe book from the pack?",
    "collectionRemovalTitle": "Remove from pack",
    "collections": {
      "emptyStateMessage": "There are no recipe books in this pack."
    },
    "details": "Recipe book pack details",
    "enterTitle": "Enter title",
    "noCollectionsAddedToCollectionPack": "There are no recipe books added to this pack.",
    "noCollectionsFound": "No recipe books found for ",
    "saveTheCollectionPack": "Do you want to save the changes before leaving?",
    "searchAddedCollections": "Search added recipe books",
    "title": "Recipe book pack title",
    "toastMessage": {
      "saved": "Recipe book pack saved"
    },
    "validation": {
      "notEnoughCollectionsForCountry": "At least {{ numberOfCollections }} recipe books from the recipe book pack must be published to the country."
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book pack information you entered. Do you want to save the recipe book pack to continue editing later?"
  },
  "collectionPublicationModal": {
    "missingRecipesPerDeviceCategory": "Recipe book does not contain at least {{ minRecipes }} recipes per appliance which are live and published in this country.",
    "noRecipesLabel": "No recipes from the recipe book have been published in this country."
  },
  "collections": {
    "notFound": "No recipe books found"
  },
  "comment": {
    "browse": {
      "all": "Browse comments"
    },
    "commentFrom": "Comment from {{ user }}",
    "consumerComments": "Consumer comments",
    "deflagging": "Deflagging comment",
    "deflaggingReasons": "You are about to deflag this comment. Please enter your reason:",
    "deleteModal": {
      "description": "Are you sure you want to delete this comment?",
      "title": "Deleting comment"
    },
    "details": "Comment details",
    "editComment": "Edit comment",
    "flag": {
      "content": "You are about to report this consumer’s comment. Please select your reporting reason:",
      "subtitle": "Flagging reason",
      "title": "Report {{ user }}'s comment"
    },
    "inContent": "In {{ content }}",
    "inRecipe": "In recipe",
    "inTip": "In article",
    "plural": "comments",
    "postComment": "Post comment",
    "postNewComment": "Post new comment",
    "reactionModal": {
      "description": {
        "like": "By liking, the user will be notified that HomeID liked their comment. Are you sure you want to proceed?",
        "unlike": "Are you sure you want to unlike this comment?"
      },
      "title": {
        "like": "Like comment",
        "unlike": "Unlike comment"
      }
    },
    "resolved": "Resolved",
    "singular": "comment",
    "toastMessage": {
      "deflag": "Comment was successfully deflaged",
      "delete": "Comment was successfully deleted"
    },
    "viewContent": "View {{ content }}",
    "viewRecipe": "View recipe",
    "viewTip": "View article"
  },
  "commentLevel": {
    "reply": "Reply",
    "rootComment": "Root comment"
  },
  "commentLevelIndicator": {
    "comment": {
      "label": "View comment"
    },
    "reply": {
      "label": "View reply"
    }
  },
  "commentState": {
    "resolved": "Resolved",
    "unresolved": "Unresolved"
  },
  "comments": {
    "commentsByConsumers": "Comments by consumers",
    "noComments": "No comments",
    "statusIndicatorLabel": "Unresolved comments"
  },
  "common": {
    "accessories": "Accessories",
    "accessory": "Accessory",
    "add": "Add",
    "addContent": "Add content",
    "addExternalLink": "Add external link",
    "addFallbackLanguage": "Add fallback language",
    "addItem": "Add {{ item }}",
    "addRecipes": "Add recipes",
    "addTags": "Add tags",
    "addedRecipes": "Added recipes ({{ items }})",
    "aiTranslationsPending": "AI translation generation is in progress for the following languages: {{ languages }}",
    "all": "All",
    "amount": "Amount",
    "and": "and",
    "announcement": {
      "plural": "Announcements"
    },
    "any": "Any",
    "appliance": {
      "choose": "Choose appliance(s)",
      "plural": "Appliances",
      "singular": "Appliance"
    },
    "articleStepTranslationRequirement": "Each step is required to have either a title or a description",
    "author": {
      "singular": "Author"
    },
    "availableIn": "Available in",
    "availableInformation": "Available information",
    "browse": {
      "aiTranslationsList": "Browse AI translation tasks",
      "collections": "Browse recipe books",
      "consumerDeletionRequests": "Consumer deletion requests",
      "consumerList": "Consumer list",
      "countries": "Browse countries",
      "customTables": "Browse custom tables",
      "homescreenCarousels": "Home Screen carousels",
      "ingredients": "Browse ingredients",
      "languageSelect": {
        "ingredients": "Display ingredients in language"
      },
      "noResultsPlacehholder": {
        "subtitle": "There are no results for this search. Please try another search.",
        "subtitleDefault": "There are currently no results.",
        "title": "No results found for '#s'",
        "titleDefault": "No results"
      },
      "noResultsPlacehholderInject": {
        "subtitleDefault": "There are currently no {{ contentType }}.",
        "subtitleDefaultSingular": "There is currently no {{ contentType }}.",
        "titleEmpty": "No {{ contentType }}"
      },
      "noResultsPlaceholderInject": {
        "title": "No {{ contentType }} found for {{ query }}"
      },
      "nutriUStories": "Browse articles",
      "packs": "Browse packs",
      "plans": "Browse message plans",
      "productRecipeLists": "Browse product recipe lists",
      "products": "Browse products",
      "promoCodes": "Browse promo codes",
      "recipeBookCarousels": "Browse recipe book carousels",
      "recipeCarousels": "Browse recipe carousels",
      "recipes": "Browse recipes",
      "reportedContent": "Reported content",
      "shopAnnouncements": "Browse shop announcements",
      "easyRecipeBook": "Browse easy recipe books",
      "campaigns": "Browse campaigns"
    },
    "bulkActions": {
      "aiTranslationsPending": "AI generation of translations for the Starter pack is in progress.",
      "subtitle": "Content translations and management.",
      "title": "Translations and content actions"
    },
    "by": "By",
    "cancel": "Cancel",
    "changesDetected": "Changes detected",
    "checkContentForMoreDetails": "Please check content for more details",
    "choose": {
      "country": "Choose country",
      "domain": "Choose domain",
      "language": "Choose language",
      "variant": "Choose variant",
      "variants": "Choose variants"
    },
    "chooseContentType": "Choose content type",
    "chooseItem": "Choose {{ item }}",
    "codename": "Codename",
    "collection": {
      "plural": "recipe books"
    },
    "collectionPack": {
      "plural": "recipe book packs",
      "singular": "Recipe book pack"
    },
    "collectionType": {
      "manual": "Manual"
    },
    "comment": {
      "plural": "Comments",
      "singular": "Comment"
    },
    "commentBy": "Comment by",
    "conjunction": {
      "and": " and ",
      "or": " or "
    },
    "connected": "Connected",
    "consumer": {
      "plural": "Consumers",
      "singular": "Consumer"
    },
    "consumerDetails": "Consumer details",
    "consumersReported": "Consumers reported",
    "contactEmail": "Contact email address",
    "contactEmailPlaceholder": "Enter contact email",
    "content": "Content",
    "contentInfo": "Content info",
    "contentManagement": "Content management",
    "continue": "Continue",
    "continueEditing": "Continue editing",
    "conversion": "Conversion",
    "copied": "Copied!",
    "copy": "Copy",
    "copyAllShortIds": "Copy all short IDs",
    "copyTranslationsQuestion": "Copy translations?",
    "country": {
      "plural": "Countries",
      "singular": "Country"
    },
    "createItem": "Create {{ item }}",
    "createdBy": "Created by",
    "createdByWithDate": "User name and date added",
    "ctn": "CTN",
    "ctv": "CTV",
    "dateAndTime": "Date & Time",
    "degreeCelsius": "°C",
    "degreeFahrenheit": "°F",
    "delete": "Delete",
    "description": {
      "plural": "Descriptions"
    },
    "deselectAll": "Deselect all",
    "deselectFromAllPages": "Deselect from all pages",
    "device": {
      "plural": "Devices",
      "singular": "Device"
    },
    "deviceActivation": "Device activation",
    "deviceDeactivation": "Device deactivation",
    "dialog": {
      "areYouSure": "Are you sure?",
      "discardChanges": "Discard changes",
      "discardMessage": "You have unsaved changes. Do you want to leave this page?",
      "discardTitle": "Unsaved data will be lost"
    },
    "disabled": "Disabled",
    "domain": {
      "plural": "Domains",
      "singular": "domain"
    },
    "duplicateProductVariants": "Multiple product variants with the same CTN & CTV combination were detected. CTN & CTV combination must be unique.",
    "duplicateTranslationLanguages": "Multiple translations with the same language were detected. There can be only one translation per language.",
    "edit": "Edit",
    "editItem": "Edit {{ item }}",
    "email": "Email",
    "emailLabel": {
      "placeholder": "Enter email"
    },
    "enabled": "Enabled",
    "enter": {
      "item": "Enter {{ item }}"
    },
    "enterItemTitle": "Enter {{ item }} title",
    "error": {
      "country": {
        "required": "Country is required"
      },
      "invalidInput": "Invalid input",
      "wholeNumber": "Only whole numbers are allowed."
    },
    "errors": {
      "checkConnection": "Please check the connection or the file you’re uploading.",
      "somethingWentWrong": "Something went wrong",
      "unknownError": "Unknown error"
    },
    "event": {
      "singular": "event"
    },
    "externalLink": "External link",
    "fallbackLanguage": "Fallback language",
    "filterOutdated": "Saved search contains a filter that is not available anymore. The removed filter will be ignored.",
    "filterSetup": "Filter setups",
    "filters": "Filters",
    "filtersApplied": {
      "plural": "Filters applied",
      "singular": "Filter applied"
    },
    "findByText": "Find {{ item }} by text",
    "firstTimeReported": "First time reported",
    "genericError": {
      "description": "Error is displayed in the console. Please contact developers.",
      "title": "An error occurred"
    },
    "hide": "Hide",
    "hideParameters": "Hide parameters",
    "high": "High",
    "highlight": {
      "plural": "Highlights"
    },
    "history": "history",
    "homeIdTeam": "HomeID Team",
    "hourAbbreviation": "h",
    "imageSizeValidationMessage": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px.",
    "inactive": "Inactive",
    "incompatibleDevices": "Incompatible devices",
    "ingredient": {
      "plural": "ingredients"
    },
    "insert": "Insert",
    "invalidTranslation": "The translation is not valid.",
    "itemDetails": "{{ item }} details",
    "items": "Items",
    "language": {
      "addNew": "Add new language",
      "plural": "Languages",
      "singular": "Language"
    },
    "languageOfOrigin": "Language of origin",
    "lastTimeReported": "Last time reported",
    "locked": "Locked",
    "low": "Low",
    "minuteAbbreviation": "min",
    "more": "more",
    "multipleItems": "Multiple items",
    "name": "Name",
    "no": "No",
    "noAccessories": "No accessories found",
    "noAddedRecipes": "There are no added recipes.",
    "noCountryProfiles": "Country has no profiles.",
    "noDevices": "No devices found",
    "noIngredients": "There are no selected ingredients.",
    "noItems": "There are no items",
    "noItemsFound": "No items found",
    "noResult": {
      "plural": "There are no {{ item }}",
      "singular": "There is no {{ item }}",
      "criteria": {
        "plural": "There are no {{ item }} matching this criteria."
      }
    },
    "noSavedSearches": "No saved searches",
    "noTranslations": "There are no translations.",
    "nonConnected": "Non-connected",
    "nonPremium": "Non premium",
    "none": "None",
    "notPublished": "Not published",
    "note": "Note",
    "numberOf": {
      "comments": "comments",
      "favorites": "favorites",
      "recipeBooks": "Number of recipe books",
      "recipes": "recipes",
      "views": "views"
    },
    "nutriUStory": {
      "plural": "Articles"
    },
    "nutritionalClaim": {
      "plural": "Nutritional claims"
    },
    "nutriuExternalLink": "HomeID external link",
    "onboardingAnnouncement": {
      "plural": "Onboarding announcements"
    },
    "order": "Order",
    "outdated": "Outdated",
    "palVideoLink": "PAL Video link",
    "percentage": "%",
    "philipsUser": "Philips user",
    "pinnedCollection": {
      "plural": "pinned recipe books",
      "singular": "Pinned recipe book"
    },
    "premium": "Premium",
    "premiumContent": "Premium content",
    "preselected": "Preselected",
    "preselectedAccessories": "Preselected accessories",
    "priority": "Priority",
    "product": {
      "plural": "Product models",
      "singular": "Product model"
    },
    "productActivatedToast": "The product is successfully activated.",
    "productActivationDescription": "The product can only be activated in countries that support the product appliance and have product brand defined for the device. After the activation, the product will be visible in the mobile app.",
    "productDeactivatedToast": "The product has been successfully deactivated.",
    "productDeactivationConfirmation": "By deactivating all of the product variants, the product will no longer be visible to mobile users on the device selection in this country. Are you sure you want to continue?",
    "productDeactivationDescription": "If all variants of a product are deactivated from a country, the product will not be visible in the mobile app.",
    "productFaq": "Product FAQ",
    "productManualUrl": "Product manual url",
    "productManuals": "Product manuals",
    "productRange": "Product range",
    "productRecipeList": {
      "plural": "Product recipe lists"
    },
    "products": "Products",
    "promoCode": {
      "plural": "Promo codes",
      "singular": "Promo code"
    },
    "pushMessage": {
      "plural": "Push notifications",
      "singular": "Push notification"
    },
    "questions": "Questions",
    "reaction": {
      "hasManagedLike": "Liked by HomeID",
      "numberOfLikes": "No. of likes"
    },
    "readMore": "Read more",
    "recipe": {
      "byConsumer": "By Consumer",
      "byHomeIdTeam": "By HomeID Team",
      "byPhilips": "By Philips",
      "plural": "recipes"
    },
    "recipeBook": {
      "singular": "Recipe book",
      "plural": "Recipe books"
    },
    "recipeBookCarousel": {
      "plural": "Recipe book carousels",
      "singular": "Recipe book carousel"
    },
    "recipeBookIsRequired": "Recipe book is required.",
    "recipeBooksAreRequired": "At least one recipe book must be added.",
    "recipeCarousel": {
      "plural": "Recipe carousels",
      "singular": "Recipe carousel"
    },
    "recipeList": {
      "singular": "Recipe list"
    },
    "recipeRemovalConfirmation": "Are you sure you want to remove the recipe?",
    "recipeType": "Recipe type",
    "recipesRequired": "At least one recipe must be added.",
    "referenceQuantityAndUnit": "Per {{quantity}} {{unit}}",
    "remove": "Remove",
    "removeRecipes": "Remove recipes",
    "replaceImage": {
      "description": "Do you want to replace the current image with an image from this {{ contentType }}?",
      "title": "Replace image"
    },
    "report": {
      "plural": "reports"
    },
    "reportedContent": {
      "plural": "reported content"
    },
    "reporterCountriesNo": "No. of reporter countries",
    "reporterLanguagesNo": "No. of reporter languages",
    "resetForm": "Reset form",
    "results": {
      "plural": "Results",
      "singular": "Result"
    },
    "role": {
      "add": "Add new role",
      "select": "Select role",
      "singular": "Role"
    },
    "save": "Save",
    "saveChangesBeforeLeaving": "Do you want to save the changes before leaving?",
    "saveSearch": "Save search",
    "saveSuccessToastMessage": "Your changes have been successfully saved.",
    "savedSearches": "Saved searches",
    "scheduleContent": "Schedule content",
    "scheduledBy": "Scheduled by",
    "scope": "Scope",
    "searchAiTranslationJobs": "Find AI translations by content short ID",
    "searchAlreadyExists": "Search with the same name already exists.",
    "searchArticles": "Find article by title",
    "searchProducts": "Find product by name, model or codename",
    "searchPushMessages": "Find push notification by title or text",
    "seeAll": "See all",
    "select": {
      "country": "Select country",
      "domain": "Select domain",
      "type": "Select type"
    },
    "selectAll": "Select all",
    "selectAllFilteredOptions": "Select all filtered options",
    "selectAllOnThisPage": "Select all on this page",
    "selectApplianceFirst": "Select appliance first",
    "selectCountry": "Select country",
    "selectCountryProfile": "Select country profile",
    "selectStep": "Select a step",
    "selectTimeRange": "Select time range",
    "serving": {
      "plural": "servings"
    },
    "shopAnnouncement": {
      "plural": "Shop announcements"
    },
    "shortId": "Short ID",
    "shortIds": "Short IDs",
    "show": "Show",
    "showLess": "Show less",
    "showParameters": "Show parameters",
    "socialIndicators": "Social indicators",
    "source": "Source",
    "sourceAppliance": {
      "singular": "Source product"
    },
    "status": "Status",
    "stepByStep": "Step-by-step",
    "stepNumber": "Step number",
    "steps": "Steps",
    "switchTo": "Switch to {{ item }}",
    "tenSecondVideoInfoMessage": "Please use the videos with duration up to 10 seconds.",
    "timeAndServings": "Time and servings",
    "timePublishedToCountry": "Time published to country",
    "timezone": "Timezone",
    "title": "Title",
    "toast": {
      "countryChanged": "Published countries changed successfully",
      "countryProfilesChanged": "Country profiles changed successfully"
    },
    "tool": {
      "plural": "Tools",
      "singular": "Tool"
    },
    "translation": {
      "plural": "translations",
      "singular": "Translation"
    },
    "translationHasBeenDeleted": "The translation has been deleted",
    "type": "Type",
    "unknownLabel": "Unknown label",
    "unlocked": "Unlocked",
    "unselectAll": "Unselect all",
    "unselectAllFilteredOptions": "Unselect all filtered options",
    "uploadImage": "Upload image",
    "uponApplianceChangeResult": "Upon changing appliances, all incompatible {{ items }} will be removed.",
    "validators": {
      "link": {
        "invalidProtocol": "Link can only accept URL address that starts with \"https\".",
        "invalidUrl": "URL address is not valid.",
        "required": "Link is required."
      }
    },
    "variant": {
      "plural": "Variants",
      "singular": "Variant"
    },
    "video": "Video",
    "view": {
      "addAnnouncementContent": "Add announcement content",
      "addBasicInformation": "Add basic information",
      "author": "Author",
      "basicInformation": "Basic information",
      "countriesActivated": "Countries activated",
      "description": "Description",
      "domainOwner": "Domain owner",
      "domainsAndCountriesPublished": "Domains and countries published",
      "lastModified": "Last modified",
      "sourceLanguage": "Source language",
      "statusChangedAt": "Changed at",
      "statusChangedBy": "Changed by",
      "tags": "Tags"
    },
    "viewBy": "View by",
    "viewComment": "View comment",
    "viewItem": "View item",
    "watt": "W",
    "yes": "Yes",
    "deviceGroup": "Device group",
    "ecommerceModel": "E-commerce model",
    "pressure": "Pressure",
    "temperature": "Temperature",
    "time": "Time",
    "basicSetup": "Basic setup",
    "easyRecipeBook": {
      "plural": "Easy recipe books",
      "singular": "Easy recipe book"
    },
    "image": "Image",
    "accessoriesInKit": "Accessories in kit",
    "accessoryKits": "Accessory kit",
    "includedInKits": "Included in kits",
    "brand": {
      "singular": "Brand"
    },
    "atLeastOneItemIsRequired": "At least one {{ item }} must be added.",
    "campaign": {
      "plural": "Campaigns"
    },
    "campaignFrequency": "Campaign frequency",
    "consumerImage": {
      "singular": "Consumer image"
    },
    "searchAddedItems": "Search added {{ items }} ({{ numberOfItems }})",
    "searchAddedItemsPlaceholder": "Search added {{ items }}",
    "searchItems": "Search {{ items }}",
    "to": "to",
    "timer": {
      "plural": "Timers"
    },
    "AllTranslations": "All translations",
    "contentSourceTranslation": "Content source translation",
    "onlyAiGeneratedTranslations": "Only AI-generated translations",
    "specificLanguage": "Specific language",
    "showAll": "Show all {{ item }}",
    "navigateToPreviousPage": "Close and navigate to previous page",
    "other": "Other"
  },
  "constraints": {
    "amount": {
      "greaterThan": "Amount must be greater than {{ amount }}"
    },
    "imageUpload": {
      "generic": {
        "description": "Image might be too large",
        "title": "Error while uploading image"
      },
      "maxResolution": {
        "description": "Please upload an image smaller than {{ maxWidth }}×{{ maxHeight }} px.",
        "title": "Image is too large"
      },
      "mimeType": {
        "description": "Please use images only in PNG or JPG format.",
        "title": "Image is not in supported format"
      },
      "ratio": {
        "description": "Please use images only in aspect ratio {{ ratioX }}:{{ ratioY }}.",
        "title": "Image is not in the right aspect ratio"
      },
      "resolution": {
        "description": "Please upload an image larger than {{ minWidth }}×{{ minHeight }} px.",
        "title": "Image is not large enough"
      },
      "size": {
        "description": "Maximum image size is 20MB",
        "title": "Image is too large"
      }
    }
  },
  "consumer": {
    "email": "E-mail",
    "followers": "Followers",
    "following": "Following",
    "id": "Consumer ID",
    "lastLoggedAt": "Last login",
    "mobileNumber": "Phone number",
    "name": "Name",
    "noComments": "Consumer did not write any comments",
    "noCountry": "-",
    "noRecipes": "Consumer has no recipes",
    "profileDescription": "Profile description",
    "recipes": "Consumer recipes",
    "registeredAt": "Registered at",
    "statusChange": {
      "actions": {
        "block": {
          "description": "You are about to block the consumer. Please enter your reason:",
          "title": "Block consumer"
        },
        "quarantine": {
          "description": "You are about to quarantine the consumer. Please enter your reason:",
          "title": "Quarantine consumer"
        },
        "revokeReporting": {
          "description": "You are about to revoke reports for this consumer and change consumers's status to {{ newStatus }}. Please enter your reason:",
          "title": "Revoke reporting"
        }
      },
      "error": {
        "description": "There was an error while attempting to change the consumer's status."
      },
      "modal": {
        "description": "You are about to change the consumer's status. Please enter your reason:",
        "title": "Change consumer status"
      }
    }
  },
  "consumers": {
    "rightToBeForgotten": {
      "modalDescription": "The entire backend data, which is referring to the user, shall be deleted",
      "modalTitle": "Are you sure you want to delete this consumer?",
      "searchPlaceholder": "Find consumer by e-mail address",
      "successToastMessage": "Consumer's status has been successfully changed to \"{{ status }}\""
    }
  },
  "content": {
    "reassignLanguage": {
      "title": "Reassign language"
    },
    "sourceDeviceCannotBeDeselected": "Source product cannot be removed from product range",
    "toastMessage": {
      "translationSaved": "{{ content }} translation saved successfully."
    },
    "translation": {
      "isApproved": "Translation already approved"
    },
    "view": "View content"
  },
  "contentDraftStatusReason": {
    "approvedButMovedForEditing": "Approved but moved to draft for editing",
    "justCreated": "Just created",
    "reportedAndEditRequested": "Reported and edit requested",
    "reviewRequestedButRejected": "Review requested but rejected",
    "takenOffline": "Taken offline"
  },
  "contentProductsForm": {
    "accessories": {
      "error": "Select \"Appliances\" and \"Devices\" first",
      "placeholder": "Choose accessories"
    },
    "devices": {
      "error": "Select \"Appliances\" first",
      "placeholder": "Choose devices"
    }
  },
  "contentPublishing": {
    "dialog": {
      "description": "You are about to leave this screen and discard the publishing information you entered. Do you want to save the publishing changes?",
      "title": "Save the publishing information?"
    },
    "emptyState": {
      "message": "Select desired Content and Profile in order to manage publishing"
    },
    "inactiveCountriesFilter": {
      "label": "Inactive countries ({{ number }})"
    },
    "problemLevel": {
      "error": "Not publishable",
      "info": "Publishable without warnings",
      "warning": "Publishable with warnings"
    },
    "profiles": {
      "label": "Profiles"
    },
    "publicationChange": {
      "emptyState": "There are no countries available for publishing",
      "radio": {
        "label": "Do you want to republish it as a new content?",
        "no": "No",
        "tooltip": "This content is already published. Do you want to republish it as a new content?",
        "yes": "Yes"
      },
      "sectionLabel": "Select the countries you want to publish to or deselect if you want to unpublish"
    },
    "toastMessage": {
      "changesDiscarded": "Publishing information changes have been discarded",
      "error": "Error occurred while saving publications",
      "success": "Publications are successfully updated"
    },
    "viewContent": "View content"
  },
  "contentScheduler": {
    "confirmationDialog": {
      "description": {
        "remove": "Are you sure you want to remove this event?",
        "schedule": "Are you sure you want to schedule this event?",
        "unschedule": "Are you sure you want to unschedule this event?"
      }
    },
    "eventStatus": {
      "draft": "draft",
      "failed": "failed",
      "inProgress": "In progress",
      "partiallySuccessful": "Partially successful",
      "passed": "past event",
      "scheduled": "scheduled",
      "successful": "Successful"
    },
    "publishingProblemsModal": {
      "errorsWarningsTitle": "Scheduling errors and warnings",
      "statusReportsTitle": "Status reports",
      "title": "Scheduling errors"
    },
    "scheduledEvent": "Scheduled event",
    "successToast": {
      "remove": "The event was removed successfully",
      "schedule": "You successfully scheduled content for {{ date }}",
      "unschedule": "The event was unscheduled successfully"
    },
    "title": "Content scheduler"
  },
  "contentSearch": {
    "errors": {
      "required": "Content is required"
    },
    "label": "Content",
    "missingLanguage": "Select language first",
    "placeholder": "Search"
  },
  "contentSelector": {
    "contentSearch": {
      "placeholder": "Choose content"
    }
  },
  "contentStatus": {
    "approve": {
      "content": "You are about to approve this {{ content }}. Please enter your approval reason:",
      "title": "{{ content }} approval"
    },
    "deflag": {
      "content": "You are about to deflag this {{ content }} and move it back to Live. Please enter your reason:",
      "title": "Deflagging {{ content }}"
    },
    "delete": {
      "content": "Are you sure you want to delete this {{ content }}?",
      "title": "{{ content }} deletion"
    },
    "deleteReportedContent": {
      "content": "Please select the reason (the reason will be visible to consumer):"
    },
    "failedStatusChange": {
      "toast": "{{ content }} status has not been changed to \"{{ status }}\" due to an error"
    },
    "flag": {
      "content": "You are about to flag this {{ content }}. Please select your reason:",
      "title": "Flag {{ contentTitle }} {{ content }}"
    },
    "moveToDraft": {
      "content": "You are about to move this {{ content }} to draft. Please enter your reason:",
      "title": "{{ content }} draft"
    },
    "moveToLive": {
      "content": "You are about to make this {{ content }} live. Please enter your reason:",
      "title": "Moving the {{ content }} to live",
      "warning": "The {{ content }} has translations that are still in the draft status"
    },
    "reject": {
      "content": "You are about to reject this request. Please enter your rejection reason:",
      "title": "Reject approving request"
    },
    "requestEdit": {
      "content": "Please select the reason (the reason will be visible to consumer):",
      "title": "Request edit"
    },
    "requestReview": {
      "content": "You are about to move {{ content }} in review. Please enter your reason:",
      "title": "Move to in review"
    },
    "successStatusChange": {
      "toast": "{{ content }} status has been successfully changed to \"{{ status}}\""
    },
    "takeOffline": {
      "content": "You are about to unpublish the {{ content }} from all of the country profiles. Please enter your reason:",
      "title": "Taking the {{ content }} offline"
    }
  },
  "contentTranslations": {
    "addFilesForImport": "Add files for import",
    "clickSelectFileOrDrag": "Click select file or drag and drop your {{ fileTypes }} files",
    "import": "Import",
    "importFailed": "Failed to process translation file",
    "importTitle": "Import translations from files",
    "missingTranslations": "Missing translations",
    "moveToDraftAndEdit": "Please move the content to draft and edit listed fields before saving the translation.",
    "populateWith": "Populate with",
    "translationFiles": "Translation files"
  },
  "contentType": {
    "announcement": "Announcement",
    "collection": "recipe book",
    "healthClaimGuideline": "Health claim guideline",
    "highlight": "Highlight",
    "nutritionClaimContent": "Nutrition claim content",
    "onboardingAnnouncement": "Onboarding announcement",
    "packHolder": "Recipe book pack",
    "pinnedHolder": "Pinned recipe book",
    "productRecipeList": "Product recipe list",
    "promoCode": "Promo code",
    "pushMessage": "Push message",
    "recipe": "recipe",
    "recipeBook": "Recipe book",
    "recipeBookCarousel": "Recipe book carousel",
    "recipeCarousel": "Recipe carousel",
    "shopAnnouncement": "Shop announcement",
    "tip": "Article",
    "easyRecipeBook": "Easy recipe book",
    "campaign": "Campaign",
    "timer": "Timer"
  },
  "contentTypeHal": {
    "announcement": "Announcement",
    "collection": "Recipe book",
    "highlight": "Highlight",
    "pushMessage": "Push notification",
    "recipe": "Recipe",
    "tip": "Article"
  },
  "cookingVariable": {
    "addNewCookingVariable": "Add new source product",
    "addNewSegment": "Add another set of parameters",
    "airSpeed": {
      "label": "Air speed",
      "placeholder": "Choose air speed"
    },
    "buildDuration": {
      "label": "Build pressure time"
    },
    "childCookingMethods": {
      "label": "Sub-Cooking method",
      "placeholder": "Choose sub-cooking method"
    },
    "cookingMethods": {
      "label": "Cooking method",
      "placeholder": "Choose cooking method"
    },
    "deleteDialog": {
      "description": "Are you sure you want to delete this source product?",
      "title": "Delete source product"
    },
    "duplicateDialog": {
      "description": {
        "confirmation": "Do you want to automatically add the same source product and product range with the {{ voltage }} electric system and with the same recipe parameters?",
        "info": "Airfryer devices from the Compact range do not have an impact on the recipe parameters in terms of voltage."
      },
      "title": "Duplicate for {{ voltage }} electric system?"
    },
    "duration": {
      "label": "Duration",
      "maxDeviceLimit": {
        "temperatureOver": "Maximum cooking time for temperature {{ temperature }} and over is {{ duration }}",
        "temperatureUnder": "Maximum cooking time for temperature under {{ temperature }} is {{ duration }}"
      }
    },
    "editCookingVariable": "Edit source product",
    "errors": {
      "genericNotInInterval": "{{ fieldName }} must be in increments of {{ interval }} {{ unit }}",
      "mustBeExact": "{{ field }} must be {{ value }}",
      "mustBeGreaterOrEqualThan": "{{ field }} must be greater than or equal to {{ min }}",
      "mustBeGreaterThan": "{{ field }} must be greater than {{ min }}",
      "mustBeInRange": "{{ field }} must be between {{ min }} and {{ max }}",
      "mustBeLowerOrEqualThan": "{{ field }} must be lower than or equal to {{ max }}",
      "mustBeLowerThan": "{{ field }} must be lower than {{ max }}",
      "notInIterval": "Temperature must be in increments of {{ interval }} {{ unit }}"
    },
    "humidity": {
      "high": {
        "label": "High"
      },
      "label": "Humidity",
      "medium": {
        "label": "Medium"
      },
      "off": {
        "label": "Off"
      },
      "placeholder": "Choose humidity"
    },
    "humidityLevel": {
      "high": {
        "label": "High"
      },
      "label": "Humidity",
      "medium": {
        "label": "Medium"
      },
      "off": {
        "label": "Off"
      },
      "placeholder": "Choose humidity"
    },
    "multipleParameterSets": "Multiple parameter sets",
    "numberOfShakes": {
      "label": "No. of shakes"
    },
    "pan": {
      "label": "Pan"
    },
    "power": {
      "label": "Power",
      "placeholder": "00"
    },
    "pressure": {
      "label": "Pressure",
      "placeholder": "0"
    },
    "profileClearConfirmationMessage": "This will clear current cooking profile settings. Are you sure you wish to continue?",
    "releaseDuration": {
      "label": "Release pressure time"
    },
    "speed": {
      "label": "Speed"
    },
    "temperature": {
      "label": "Temperature"
    },
    "temperatureInCelsius": {
      "label": "Temperature"
    },
    "warnings": {
      "genericNotInInterval": "{{ fieldName }} should be in increments of {{ interval }} {{ unit }}",
      "notInIterval": "{{ field }} should be in increments of {{ interval }} {{ unit }}",
      "shouldBeExact": "{{ field }} should be {{ value }}",
      "shouldBeGreaterOrEqualThan": "{{ field }} should be greater than or equal to {{ min }}",
      "shouldBeGreaterThan": "{{ field }} should be greater than {{ min }}",
      "shouldBeInRange": "{{ field }} should be between {{ min }} and {{ max }}",
      "shouldBeLowerOrEqualThan": "{{ field }} should be lower than or equal to {{ max }}",
      "shouldBeLowerThan": "{{ field }} should be lower than {{ max }}"
    }
  },
  "copyToClipboard": {
    "label": {
      "model": "Copy model",
      "shortID": "Copy short ID"
    }
  },
  "countries": {
    "searchPlaceholder": "Find country by name or code"
  },
  "country": {
    "addNew": "Add new country",
    "appliancesPlaceholder": "Choose appliance(s)",
    "code": "Country code",
    "codeLockWarning": "Once you save the new country you won't be able to change the country code.",
    "codePlaceholder": "Enter country code",
    "communityPlaceholder": "Choose community availability",
    "confirmActivationModal": {
      "content": "Activating a country enables content to be assigned to that country, and importing and exporting translations for this country’s assigned languages. ",
      "title": "Activating a country"
    },
    "create": "Create a country",
    "details": "Country details",
    "deviceRegion": {
      "china": "China",
      "label": "Device region",
      "placeholder": "Choose device region",
      "world": "World"
    },
    "edit": "Edit country",
    "errors": {
      "couldNotActivate": "Could not activate the country due to an error."
    },
    "features": {
      "basic": "Basic",
      "community": "Community",
      "label": "Country features"
    },
    "flag": {
      "image": "Flag icon image",
      "preview": "Flag icon preview"
    },
    "languages": "Country languages",
    "marketingOptIn": {
      "label": "Marketing opt-in",
      "placeholder": "Choose marketing opt-in availability"
    },
    "name": "Country name",
    "namePlaceholder": "Enter country name",
    "supportedLanguages": "Supported languages",
    "supportedLanguagesPlaceholder": "Choose languages",
    "toastMessage": {
      "saved": "Country saved"
    },
    "unitSystem": "Unit System",
    "unitSystemPlaceholder": "Choose unit system"
  },
  "countryName": {
    "AA": "Global",
    "AE": "United Arab Emirates",
    "AL": "Albania",
    "AO": "Angola",
    "AQ": "Global",
    "AR": "Argentina",
    "AT": "Austria",
    "AU": "Australia",
    "BA": "Bosnia-Herzegovina",
    "BE": "Belgium",
    "BG": "Bulgaria",
    "BH": "Bahrain",
    "BN": "Brunei",
    "BR": "Brazil",
    "CA": "Canada",
    "CH": "Switzerland",
    "CL": "Chile",
    "CN": "China",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DE": "Germany",
    "DK": "Denmark",
    "EE": "Estonia",
    "EG": "Egypt",
    "ES": "Spain",
    "FI": "Finland",
    "FR": "France",
    "GB": "United Kingdom",
    "GH": "Ghana",
    "GR": "Greece",
    "HK": "Hong Kong",
    "HR": "Croatia",
    "HU": "Hungary",
    "ID": "Indonesia",
    "IE": "Ireland",
    "IH": "HomeID Team IHUT",
    "II": "HomeID Team Nederland (IHUT)",
    "IJ": "HomeID Team 中国 (IHUT)",
    "IL": "Israel",
    "IN": "India",
    "IS": "Iceland",
    "IT": "Italy",
    "JO": "Jordan",
    "KE": "Kenya",
    "KH": "Cambodia",
    "KR": "South Korea",
    "KW": "Kuwait",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "LV": "Latvia",
    "MD": "Moldova",
    "ME": "Montenegro",
    "MK": "North Macedonia",
    "MO": "Macau",
    "MU": "Mauritius",
    "MX": "Mexico",
    "MY": "Malaysia",
    "NA": "Namibia",
    "NG": "Nigeria",
    "NL": "Netherlands",
    "NO": "Norway",
    "NZ": "New Zealand",
    "OM": "Oman",
    "PE": "Peru",
    "PH": "Philippines",
    "PK": "Pakistan",
    "PL": "Poland",
    "PT": "Portugal",
    "QA": "Qatar",
    "QM": "HomeID Team Austria (IHUT)",
    "QN": "Test country",
    "RO": "Romania",
    "RS": "Serbia",
    "RU": "Russia",
    "SA": "Saudi Arabia",
    "SD": "Sudan",
    "SE": "Sweden",
    "SG": "Singapore",
    "SI": "Slovenia",
    "SK": "Slovakia",
    "TH": "Thailand",
    "TR": "Turkey",
    "TW": "Taiwan",
    "UA": "Ukraine",
    "US": "United States of America",
    "UY": "Uruguay",
    "VN": "Vietnam",
    "XK": "Kosovo",
    "ZA": "South Africa"
  },
  "countryProfile": {
    "plural": "Country profiles",
    "singular": "Country profile"
  },
  "countryProfileModal": {
    "languageDoesNotMatchProfile": "Languages does not match to any country profile."
  },
  "countryProfilesModal": {
    "title": {
      "onlyPublish": "Select the countries you want to publish:",
      "unpublish": "Select the countries you want to publish to or deselect if you want to unpublish:"
    }
  },
  "cropping": {
    "crop": "Crop",
    "cropping": "Cropping image",
    "preparing": "Preparing cropper",
    "warning": {
      "keepCroppingButton": "Keep cropping",
      "leaveButton": "Leave anyway",
      "leaveContent": "You are currently cropping an image. Leaving will not save the image that is being cropped.",
      "saveButton": "Save anyway",
      "saveContent": "You are currently cropping an image. Image that is currently being cropped will not be saved.",
      "title": "Image is being cropped"
    }
  },
  "customFilterModal": {
    "error": "Name already exists",
    "placeholder": "Enter filter name",
    "title": "Enter the name of the filter set you want to save"
  },
  "customTable": {
    "action": {
      "createNewTableRow": "Create new table row",
      "deleteTableAndData": "Delete the table and table data",
      "importData": "Import table data",
      "overwriteData": "Overwrite table data",
      "tableContent": "Table content"
    },
    "column": {
      "addNew": "Add new column",
      "allowedValues": {
        "label": "Allowed values",
        "placeholder": "value1,value2,value3",
        "required": "Allowed values are required",
        "tooltip": "Allowed values separated by a comma"
      },
      "description": {
        "placeholder": "Enter column description"
      },
      "name": {
        "label": "Column name",
        "noWhitespace": "Column name must not contain whitespace characters",
        "placeholder": "Enter column name"
      },
      "nullable": {
        "label": "Is nullable?"
      },
      "type": {
        "label": "Column type",
        "placeholder": "Select column type"
      }
    },
    "deleteTableRowDialog": {
      "description": "Are you sure you want to delete the table row?",
      "title": "Table row deletion"
    },
    "displayName": {
      "placeholder": "Enter display name"
    },
    "form": {
      "create": "Create custom table",
      "edit": "Edit custom table"
    },
    "header": {
      "columnName": "Column name",
      "columnType": "Column type",
      "isNullable": "Nullable"
    },
    "import": {
      "buttonLabel": "Import data",
      "description": "This action will overwrite the previous table data. The previous table data will be lost. Do you want to proceed with the action?",
      "modalTitle": "Import table data",
      "success": "Table data successfully imported"
    },
    "row": {
      "formTitle": "Table row form"
    },
    "rowValidation": {
      "allowedValues": "The entered value is not allowed"
    },
    "subtitle": {
      "columns": "Table columns",
      "dangerZone": "Danger zone"
    },
    "tableData": {
      "export": "Export table data",
      "noColumns": "Table columns are not defined",
      "noData": "There is no data in the table"
    },
    "tableDeletion": {
      "description": "This action is irreversible. The table and all of its data will be deleted. Do you want to proceed with the deletion?",
      "title": "Table deletion"
    },
    "tableRow": {
      "deleted": "The table row has been deleted",
      "saved": "Table row has been successfully saved"
    },
    "toastMessage": {
      "saved": "Table definition saved"
    },
    "uniqueIdentifier": {
      "label": "Unique identifier",
      "placeholder": "Enter unique table identifier"
    },
    "validation": {
      "displayNameRequired": "Display name is required",
      "uniqueIdentifier": "Unique table identifier is required",
      "uniqueIdentifierNoWhitespace": "Unique identifier must not contain whitespace characters"
    }
  },
  "customTables": {
    "actions": {
      "editDefinition": "Edit definition"
    },
    "header": {
      "displayName": "Table name",
      "uniqueIdentifier": "Unique identifier"
    }
  },
  "dashboard": {
    "statistics": {
      "alternativeTitle": "Welcome!",
      "header": {
        "active": "Active",
        "active30": {
          "subtitle": "30 days",
          "title": "Active",
          "tooltip": "Used the app in the last month"
        },
        "all": "All",
        "live": "Live",
        "new30": {
          "subtitle": "30 days",
          "title": "New",
          "tooltip": "Users registered in the last month"
        },
        "other": "Other"
      },
      "subtitle": "Here’s what’s been happening in the HomeID community",
      "title": "Welcome, {{ name }}!",
      "total": "TOTAL"
    }
  },
  "datetimepicker": {
    "date": {
      "label": "Date",
      "placeholder": "Choose date"
    },
    "time": {
      "label": "Time",
      "placeholder": "Choose time"
    }
  },
  "deepLinkGenerator": {
    "modal": {
      "description": "We've generated a URL link that leads to this content's mobile screen. You can copy the link to your clipboard by clicking the button next to the URL.",
      "title": "Mobile app deep link URL"
    },
    "tooltip": "Generate a new URL to the mobile application for this content",
    "copied": "Link copied to clipboard: {{ link }}"
  },
  "deleteSearch": {
    "description": "This action can’t be undone. ",
    "title": "Delete “{{ name }}” search"
  },
  "deliveryStatus": {
    "sent": "Sent"
  },
  "deviceFamily": {
    "airfryer": "Airfryer",
    "blendersAndJuicers": "Blenders/Juicers"
  },
  "dialog": {
    "selectVisibleReason": "Please select the reason (the reason will be visible to consumer):",
    "title": {
      "requestDeletion": "Request deletion",
      "requestEdit": "Request edit"
    }
  },
  "domain": {
    "singular": "Domain"
  },
  "domainName": {
    "africa": "Africa",
    "apac": "APAC",
    "australia": "Australia",
    "baltic": "Baltic",
    "benelux": "BENELUX",
    "cee": "CEE",
    "ceer": "CEER",
    "china": "China",
    "dach": "DACH",
    "france": "France",
    "global": "Global",
    "greece": "Greece",
    "iberia": "Iberia",
    "ihut": "IHUT",
    "iig": "IIg",
    "it": "IT",
    "latam": "Latin America",
    "met": "MET",
    "meta": "META",
    "middleeast": "Middle East",
    "nordics": "Nordics",
    "northamerica": "North America",
    "poland": "Poland",
    "singapore": "Singapore",
    "southkorea": "South Korea",
    "turkey": "Turkey",
    "uki": "UKI",
    "unitedarabemirates": "United Arab Emirates"
  },
  "duration": {
    "validation": {
      "isDurationInMinutes": {
        "message": "The duration must be a whole number or a rounded minute value."
      }
    }
  },
  "electricSystem": {
    "110V": {
      "longLabel": "Low voltage (100-127 V)",
      "shortLabel": "Low voltage"
    },
    "220V": {
      "longLabel": "High voltage (220-240 V)",
      "shortLabel": "High voltage"
    },
    "label": "Electric system",
    "placeholder": "Select electric system"
  },
  "error": {
    "generic": "Error",
    "hasErrors": "{{ item }} has errors",
    "httpResponse": "Server responded:",
    "http_0": "Something went wrong",
    "http_400": "Bad request",
    "http_401": "Unauthorized",
    "http_403": "Forbidden",
    "http_404": "Item not found",
    "http_500": "Internal server error",
    "http_503": "Service unavailable",
    "isRequired": "{{ item }} is required",
    "itemExists": "Item already exists",
    "maxLength": "Number of characters cannot exceed {{length}} "
  },
  "exportRecipeModal": {
    "includeMedia": "Include images & video URLs"
  },
  "exportTranslationsDialog": {
    "referenceLanguage": "Reference Language",
    "selectReferenceLanguage": "Select the reference language for export"
  },
  "fileUploader": {
    "deleteImageDialog": {
      "description": "Are you sure you want to delete this image?",
      "title": "Image deletion"
    },
    "importResultLevel": {
      "errors": "Errors",
      "warnings": "Warnings"
    },
    "uploadFailed": "Upload failed",
    "uploadSuccessful": "Upload successful",
    "uploading": "Uploading..."
  },
  "filter": {
    "accessories": {
      "label": "Accessories",
      "notFound": "No accessories found"
    },
    "announcementType": "Announcement type",
    "category": "Category",
    "commentState": "Comment state",
    "country": "Country",
    "dateCreated": {
      "label": "Date created",
      "options": {
        "lastWeek": "Last week",
        "lastYear": "Last year",
        "thisMonth": "This month",
        "thisWeek": "This week",
        "thisYear": "This year"
      }
    },
    "devices": "Product model",
    "domain": "Domain",
    "language": "Language",
    "lockedIngredient": {
      "label": "Locked status",
      "locked": "Locked",
      "unlocked": "Unlocked"
    },
    "notInDatabases": "Not in databases",
    "nutritionDatabase": "Nutrition database",
    "ownerType": "Owner type",
    "publishedAt": "Time published to profile",
    "publishedInCountry": "Published in country",
    "qualityIndicator": {
      "calorieDifference": "kcal mismatch among databases",
      "fat": "High in fat",
      "label": "Quality indicator",
      "protein": "High in protein",
      "sodium": "High in sodium"
    },
    "reported": {
      "label": "Reported",
      "no": "Not reported",
      "yes": "Reported"
    },
    "role": "Role",
    "status": "Status",
    "tags": {
      "label": "Tags",
      "notFound": "No tags found",
      "selectDevice": "Select appliance first"
    },
    "topLevelOnly": {
      "allLevels": "Comment and replies",
      "label": "Comment levels",
      "onlyTopLevel": "Only top-level comments"
    },
    "translatable": {
      "label": "Translatable status",
      "notTranslatable": "Not translatable",
      "translatable": "Translatable"
    },
    "useInRecipes": {
      "label": "Use in recipes",
      "onlyUnused": "Only unused ingredients",
      "onlyUsed": "Only used ingredients"
    },
    "video": {
      "doesNotHaveVideo": "Does not have video",
      "hasVideo": "Has video",
      "label": "Video"
    },
    "featured": {
      "isFeatured": "Is featured",
      "isNotFeatured": "Is not featured",
      "label": "Featured"
    },
    "pinned": {
      "isNotPinned": "Is not pinned",
      "isPinned": "Is pinned",
      "label": "Pinned"
    }
  },
  "filterCreator": {
    "appliance": {
      "label": "Appliance",
      "placeholder": "Choose appliance"
    },
    "domain": {
      "label": "Domain",
      "placeholder": "Choose domain"
    },
    "language": {
      "label": "Language",
      "placeholder": "Choose language"
    },
    "languageStatus": {
      "label": "Language status",
      "placeholder": "Choose language status"
    },
    "ownerType": {
      "label": "Owner type",
      "placeholder": "Choose owner type"
    },
    "resourceType": {
      "label": "Content type",
      "placeholder": "Choose content type"
    },
    "status": {
      "label": "Status",
      "placeholder": "Choose status"
    }
  },
  "filters": {
    "publishedByProfile": "Published by profile"
  },
  "fractionForm": {
    "errors": {
      "greatherThanZero": "All numbers must be greather than 0",
      "notANumber": "Input is not a valid number",
      "numeratorOrDenominatorTooHigh": "Numerator and denominator must be less than {{ max }}",
      "numeratorTooHigh": "Numerator must be lower than denominator",
      "partialFraction": "Both part of the fraction needs to be entered",
      "required": "Amount is required"
    }
  },
  "gram": {
    "abbreviation": "g"
  },
  "highlight": {
    "browse": {
      "title": "Browse highlights"
    },
    "details": "Highlight details",
    "noResultsTitle": "No highlight found for  '{{ query }}'"
  },
  "highlights": {
    "copyContentTranslationsDescription": "Do you want to copy all the translations from the selected content to the highlight? If yes, highlight translations will be created from content translations, and will be pre-populated with the content translation title and language."
  },
  "history": {
    "event": {
      "commentDeflag": "Comment deflagged",
      "commentDelete": "Comment deleted",
      "contentStatusChange": "Status changed",
      "contentStatusChangedTo": "Status changed to",
      "ingredientsAnalyzed": "Ingredients analysed",
      "label": "Event",
      "missingUnitDetected": "Missing unit detected",
      "profileStatusChange": "Status changed",
      "publish": "Published",
      "unpublish": "Unpublished"
    },
    "newStatus": "New status",
    "noHistory": "No history"
  },
  "imageUpload": {
    "announcementValidationMessage": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px and with ratio {{ ratioX }}×{{ ratioY }}. Recommended image resolution is 4000x3000px.",
    "fixedSizeValidationMessage": "You can upload only PNG or JPG images of size {{ minWidth }}×{{ minHeight }}px",
    "validationMessage": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px and with ratio {{ ratioX }}×{{ ratioY }}",
    "validationMessageWithoutRatio": "You can upload only PNG or JPG images larger than {{ minWidth }}×{{ minHeight }}px"
  },
  "importResultType": {
    "invalidFileProvided": "Invalid file provided",
    "invalidTranslationProvided": "Invalid translation provided",
    "languageNotSupported": "Language is not supported",
    "numberStepTranslationDoesNotMatchSource": "Number of steps in the translation does not match the source",
    "sourceMissing": "Source missing",
    "sourceTranslationChanged": "Source translation changed",
    "sourceTranslationMissing": "Source translation missing",
    "targetLanguageMatchesSourceLanguage": "Target language matches the source language",
    "targetTranslationMissing": "Target translation missing",
    "translationImported": "Translation imported",
    "translationStillMissing": "Translation still missing",
    "translationUpdated": "Translation updated",
    "userDoesNotHavePermissionToImport": "User does not have permission to import"
  },
  "ingredient": {
    "addNew": {
      "default": "Add new ingredient"
    },
    "addToRecipe": {
      "amountMetric": "Amount (metric)",
      "name": "Ingredient name",
      "new": {
        "add": "Add \"{{ newIngredientName }}\" as ingredient"
      },
      "selectLanguageFirst": "Please select language before adding ingredients",
      "usCustomary": "Amount (US customary)"
    },
    "allAppliances": "All appliances",
    "allergens": {
      "approveModalDescription": "You are about to approve food sensitivites and intolerances of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve food sensitivites and intolerances",
      "approved": "Food sensitivities and intolerances are approved.",
      "history": {
        "approved": "Approved Food sensitivities and intolerances.",
        "unapproved": "Unapproved Food sensitivities and intolerances."
      },
      "notApproved": "Food sensitivities and intolerances are not approved.",
      "otherLabel": "Other",
      "otherPlaceholder": "Select other",
      "unapproveModalDescription": "You are about to unapprove food sensitivites and intolerances of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove food sensitivites and intolerances"
    },
    "applianceType": "Appliance type",
    "author": {
      "label": "Ingredient author"
    },
    "choose": "Choose ingredient",
    "comment": {
      "noComment": "There are no comments on this ingredient",
      "placeholder": "Use this text area to enter comments about changes to the ingredient",
      "title": "Comments"
    },
    "create": "Create ingredient",
    "creator": {
      "admin": "Philips",
      "consumer": "Consumer",
      "table": {
        "admin": "P",
        "consumer": "C",
        "unknown": "-"
      },
      "unknown": "Not specified"
    },
    "details": {
      "title": "Ingredient details"
    },
    "displayName": "Display name",
    "history": "Ingredient history",
    "id": {
      "label": "Ingredient ID"
    },
    "ingredientTranslations": {
      "deleteModalTitle": "Deleting translation",
      "title": "Ingredient translations",
      "validator": {
        "plural": "Ingredient must have a translation for plural in language {{ language }}.",
        "singular": "Ingredient must have a translation for singular in language {{ language }}."
      }
    },
    "isInNutritionDatabase": "Is in nutrition database",
    "isNotInNutritionDatabase": "Is not in nutrition database",
    "isNotMappedToDatabase": "Is not mapped to database {{ database }}",
    "lock": "Lock ingredient",
    "locked": "Locked status",
    "lockedIngredient": {
      "history": {
        "approved": "Locked ingredient.",
        "unapproved": "Unlocked ingredient."
      },
      "tooltip": "If ingredient is locked it can not be deleted."
    },
    "merge": {
      "authorConflict": {
        "title": "Choose the author of the ingredient"
      },
      "conflictTitle": "Resolve conflict cases",
      "nutritionInfoConflict": {
        "title": "Choose which database mappings you want to keep"
      },
      "specificConversionConflict": {
        "title": "Choose which conversions you want to keep"
      },
      "translationConflict": {
        "title": "Choose which translations you want to keep"
      }
    },
    "mergeErrorModalTitle": "Error while replacing ingredient",
    "mergeModelTitle": "Replace “{{ ingredient }}” ingredient with",
    "mergeSuccessModalButton": "View ingredient",
    "mergeSuccessModalButtonToBrowse": "Browse ingredients",
    "mergeSuccessModalContent": "The ingredient was replaced with the {{ title }} ({{ id }}).",
    "mergeSuccessModalTitle": "Ingredient replaced",
    "missingMappings": {
      "long": "Please add nutritional database mappings for databases: {{ databases }}.",
      "modalTitle": "Ingredient is missing some database mappings",
      "short": "Missing mappings for databases: {{ databases }}"
    },
    "nameConflict": "Conflicting with another ingredient",
    "nameOrId": "Ingredient name or ID",
    "namePlural": {
      "label": "Name plural",
      "placeholder": "Enter name in plural",
      "required": "Name plural is required"
    },
    "nameSingular": {
      "label": "Name singular",
      "placeholder": "Enter name in singular",
      "required": "Name singular is required"
    },
    "noResults": "No ingredients found",
    "noTranslations": {
      "description": "There is no translations for this ingredient",
      "title": "No translations"
    },
    "notTranslatable": {
      "history": {
        "approved": "Changed translatable status to not translatable.",
        "unapproved": "Changed translatable status to translatable."
      },
      "label": "Not translatable",
      "mergeTitle": "Choose which value you want to keep",
      "message": "Ingredient is not translatable.",
      "tooltip": "If ingredient is marked as not translatable its translation won’t be exported by default."
    },
    "nutritionMapping": {
      "addMapping": "Add nutrition mapping",
      "approveModalDescription": "You are about to approve nutrition information of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve nutrition information",
      "approved": "Nutrition information is approved.",
      "deleteModalTitle": "Deleting nutrition information mapping",
      "history": {
        "approved": "Approved Nutritional information.",
        "unapproved": "Unpproved Nutritional information."
      },
      "missing": {
        "description": "There is no nutritional information for this ingredient. Go to edit screen to add it."
      },
      "noMappings": "Nutritional information not available",
      "noResultInDatabase": "No nutrition info found in selected database",
      "notApproved": "Nutrition information is not approved.",
      "providerName": "Ingredient name",
      "providerNamePlaceholder": "Enter provider ingredient name",
      "sourceDatabase": "Source database",
      "sourceDatabasePlaceholder": "Select database",
      "tableHeader": {
        "ifc": "IFC",
        "providerText": "Provider text",
        "quantity": "Ref. quantity",
        "source": "Source",
        "unit": "Ref. unit"
      },
      "title": "Nutritional information",
      "unapproveModalDescription": "You are about to unapprove nutrition information of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove nutrition information"
    },
    "nutritionalBreakdown": {
      "source": "Source",
      "title": "Nutritional breakdown"
    },
    "ownershipModal": {
      "description": "Choose an appropriate author to which you want to reassign the ingredient. After you change the author to Philips you cannot change it back.",
      "title": "Change ingredient ownership"
    },
    "plural": "Ingredients",
    "reassignOwnership": {
      "button": "Change author"
    },
    "referencedInRecipes": {
      "label": "Referenced in recipes"
    },
    "replace": "Replace",
    "replaceWith": "Replace with ...",
    "requiresAtLeastOneConversion": {
      "long": "This ingredient does not have required conversion for selected unit.<br><br>Please convert at least one of these units to grams: {{ units }}.",
      "modalTitle": "Ingredient is missing some conversions",
      "short": "Missing conversion from {{ units }} to grams"
    },
    "saveChangesDialog": {
      "description": "Before continuing you need to save changes made to the ingredient.",
      "title": "Save changes?"
    },
    "singular": "Ingredient",
    "toastMessage": {
      "ownershipChanged": "Ingredient author changed",
      "saved": "Ingredient saved"
    },
    "translatable": {
      "label": "Translatable",
      "status": "Translatable status"
    },
    "translation": {
      "addNew": "Add new ingredient translation",
      "display": "Display",
      "edit": "Edit ingredient translation",
      "plural": "Plural",
      "singular": "Singular"
    },
    "translationConflict": {
      "message": "already has translation <span class=\"translation-highlight\">{{ name }}</span> in language <span class=\"translation-highlight\">{{ language }}</span>.",
      "title": "Existing translations"
    },
    "unitConversion": {
      "add": "Add unit conversion",
      "approveModalDescription": "You are about to approve specific unit conversion of this ingredient. Please enter your approval reason:",
      "approveModalTitle": "Approve specific unit conversion",
      "approved": "Specific unit conversions are approved.",
      "conversionAmount": "Conversion amount",
      "deleteModalTitle": "Deleting unit conversion",
      "destinationAmount": "Amount ({{ unit }})",
      "destinationAmountPlaceholder": "Enter conversion amount",
      "edit": "Edit unit conversion",
      "history": {
        "approved": "Approved Specific unit conversions.",
        "unapproved": "Unapproved Specific unit conversions."
      },
      "missing": {
        "description": "There is no unit conversion information for this ingredient. Go to edit screen to add it.",
        "title": "Specific units conversion not available"
      },
      "noConversions": "There are no unit conversions for this ingredient",
      "notApproved": "Specific unit conversions are not approved.",
      "sourceAmount": "Amount ({{ unit }})",
      "sourceAmountPlaceholder": "Enter source unit amount",
      "sourceUnit": "Source unit",
      "sourceUnitPlaceholder": "Select unit",
      "title": "Specific units conversion",
      "unapproveModalDescription": "You are about to unapprove specific unit conversion of this ingredient. Please enter your unapproval reason:",
      "unapproveModalTitle": "Unapprove specific unit conversion"
    },
    "view": "View ingredient",
    "delete": {
      "description": "Are you sure you wish to delete this ingredient?",
      "success": "Ingredient was successfully deleted.",
      "title": "Delete ingredient"
    },
    "noRecipes": "Ingredient is not used in any recipes."
  },
  "ingredientUnit": {
    "bag": {
      "empty": "bags",
      "plural": "bags",
      "singular": "bag"
    },
    "branch": {
      "empty": "branches",
      "plural": "branches",
      "singular": "branch"
    },
    "bunch": {
      "empty": "bunches",
      "plural": "bunches",
      "singular": "bunch"
    },
    "can": {
      "empty": "cans",
      "plural": "cans",
      "singular": "can"
    },
    "centiliter": {
      "empty": "centiliters",
      "plural": "centiliters",
      "singular": "centiliter"
    },
    "clove": {
      "empty": "cloves",
      "plural": "cloves",
      "singular": "clove"
    },
    "cup": {
      "empty": "cups",
      "plural": "cups",
      "singular": "cup"
    },
    "dash": {
      "empty": "dashes",
      "plural": "dashes",
      "singular": "dash"
    },
    "decigram": {
      "empty": "decigram",
      "plural": "decigram",
      "singular": "decigram"
    },
    "deciliter": {
      "empty": "deciliters",
      "plural": "deciliters",
      "singular": "deciliter"
    },
    "drop": {
      "empty": "drops",
      "plural": "drops",
      "singular": "drop"
    },
    "fluidOunce": {
      "empty": "fluid ounces",
      "plural": "fluid ounces",
      "singular": "fluid ounce"
    },
    "gram": {
      "empty": "grams",
      "plural": "grams",
      "singular": "gram"
    },
    "kilogram": {
      "empty": "kilograms",
      "plural": "kilograms",
      "singular": "kilogram"
    },
    "liter": {
      "empty": "liters",
      "plural": "liters",
      "singular": "liter"
    },
    "microgram": {
      "empty": "micrograms",
      "plural": "micrograms",
      "singular": "microgram"
    },
    "milligram": {
      "empty": "milligrams",
      "plural": "milligrams",
      "singular": "milligram"
    },
    "milliliter": {
      "empty": "milliliters",
      "plural": "milliliters",
      "singular": "milliliter"
    },
    "ounce": {
      "empty": "ounces",
      "plural": "ounces",
      "singular": "ounce"
    },
    "package": {
      "plural": "packages",
      "singular": "package"
    },
    "piece": {
      "empty": "pieces",
      "plural": "pieces",
      "singular": "piece"
    },
    "pinch": {
      "empty": "pinches",
      "plural": "pinches",
      "singular": "pinch"
    },
    "pound": {
      "empty": "pounds",
      "plural": "pounds",
      "singular": "pound"
    },
    "sachet": {
      "empty": "sachet",
      "plural": "sachet",
      "singular": "sachet"
    },
    "scoop": {
      "empty": "scoops",
      "plural": "scoops",
      "singular": "scoop"
    },
    "shot": {
      "empty": "shots",
      "plural": "shots",
      "singular": "shot"
    },
    "slice": {
      "empty": "slices",
      "plural": "slices",
      "singular": "slice"
    },
    "stick": {
      "empty": "sticks",
      "plural": "sticks",
      "singular": "stick"
    },
    "tablespoon": {
      "empty": "tablespoons",
      "plural": "tablespoons",
      "singular": "tablespoon"
    },
    "teaspoon": {
      "empty": "teaspoons",
      "plural": "teaspoons",
      "singular": "teaspoon"
    }
  },
  "ingredients": {
    "recipes": {
      "emtpyState": "There are no recipes for this ingredient"
    }
  },
  "ingredinet": {
    "action": {
      "plural": "Actions"
    }
  },
  "invalidEmail": {
    "errorMessage": "Invalid e-mail address"
  },
  "invalidIdsDialog": {
    "description": "As following ID inputs don’t match or don’t exist in the system they can not be shown in the preview list. Review the entered ID list and remove wrong IDs in order to succesfully save the list.",
    "title": "Wrong ID input",
    "wrongIdsSubtitle": "Wrong ID inputs"
  },
  "labels": {
    "searchInputPlaceholder": "Search recipe books"
  },
  "landingPage": {
    "goToChangelogButton": "See what's new",
    "title": "Welcome to AdminUI"
  },
  "language": {
    "choose": "Choose language",
    "delete": "Delete language",
    "singular": "Language"
  },
  "limitExceededDialog": {
    "description": "The max number of items that you can export is",
    "title": "Limit exceeded"
  },
  "login": {
    "password": "Password",
    "username": "Username"
  },
  "macronutrients": {
    "title": "Macronutrients"
  },
  "menu": {
    "aiTranslations": "AI translation tasks",
    "create": {
      "accessory": "Create accessory",
      "announcement": "Create announcement",
      "collection": "Create a recipe book",
      "device": "Create device",
      "highlights": "Create highlight",
      "ingredient": "Create ingredient",
      "nutriUStory": "Create article",
      "onboardingAnnouncement": "Create onboarding announcement",
      "pinnedCollection": "Create a pinned recipe book",
      "pinnedRecipeBook": "Create a pinned recipe book",
      "plan": "Create a message plan",
      "productRecipeList": "Create a product recipe list",
      "promoCode": "Create a promo code",
      "pushMessage": "Create push notification",
      "recipe": "Create a recipe",
      "recipeBookCarousel": "Create a recipe book carousel",
      "recipeBookPack": "Create a recipe book pack",
      "recipeCarousel": "Create a recipe carousel",
      "shopAnnouncement": "Create shop announcement",
      "user": "Create user",
      "easyRecipeBook": "Create an easy recipe book",
      "campaign": "Create a campaign",
      "timers": "Create a timer"
    },
    "createVideo": {
      "digitalAssetManagement": "Upload a video"
    },
    "customTables": {
      "create": "Create a custom table"
    },
    "header": {
      "adminUiUsers": "Admin UI users",
      "announcements": "Announcements",
      "bulkManagement": "Translations & bulk actions",
      "collections": "Recipe books",
      "comments": "Comments",
      "consumers": "Consumer list",
      "contentPublishing": "Content publishing",
      "contentScheduler": "Content scheduler",
      "countries": "Countries",
      "countryAdministration": "Country administration",
      "customTables": "Custom tables",
      "dashboard": "Dashboard",
      "digitalAssetManagement": "Digital asset management",
      "highlights": "Highlights",
      "homeScreenCarousels": "Home Screen carousels",
      "ingredients": "Ingredients",
      "nutriUStories": "Articles",
      "onboardingAnnouncements": "Onboarding announcements",
      "packs": "Packs",
      "plannedMessages": "Personalized messaging system",
      "productRecipeLists": "Product recipe lists",
      "promoCodes": "Promo codes",
      "pushMessages": "Push notifications",
      "recipes": "Recipes",
      "reportedContent": "Reported content",
      "shopAnnouncements": "Shop announcements",
      "userAdministration": "User administration",
      "campaigns": "Campaigns",
      "timers": "Timers"
    },
    "import": {
      "translations": "Import translations"
    },
    "subtitle": {
      "administration": "Administration",
      "appHomeScreen": "APP HOME SCREEN",
      "communityManagement": "COMMUNITY MANAGEMENT",
      "contentManagement": "Content management",
      "eCommerce": "E-commerce",
      "publishing": "PUBLISHING"
    }
  },
  "metrics": {
    "numberOfArticles": "Number of articles",
    "numberOfCountries": "Number of countries",
    "numberOfLinkedProducts": "Number of linked products",
    "numberOfRecipes": "Number of recipes",
    "numberOfTranslations": "Number of translations",
    "numberOfUsers": "Number of users"
  },
  "milliliter": {
    "abbreviation": "ml"
  },
  "mimeType": {
    "xml": "XML",
    "zip": "ZIP"
  },
  "mineral": {
    "calcium": "Calcium",
    "iron": "Iron",
    "magnesium": "Magnesium",
    "potassium": "Potassium",
    "salt": "Salt",
    "selenium": "Selenium",
    "sodium": "Sodium",
    "title": "Minerals",
    "zinc": "Zinc"
  },
  "minute": {
    "abbrv": {
      "plural": "mins"
    }
  },
  "missingInformationDialog": {
    "defaultErrorMessage": "Some of the fields are not valid, please edit them.",
    "editFollowingInformation": "Please edit the following information:",
    "moveToDraftMessage": "Please move the {{ contentType}} to Draft status and edit missing information.",
    "title": "Please fill missing information"
  },
  "modal": {
    "areYouSure": "Are you sure?",
    "collectionDeviceTypeChange": {
      "changeRecipeDeviceType": "Change appliance type",
      "description": "The form will be reset. Are you sure?",
      "title": "Changing recipe book appliance type"
    },
    "pleaseSelectReason": "Please select the reason:",
    "recipeDeviceTypeChange": {
      "changeRecipeDeviceType": "Change appliance type",
      "description": "The form will be reset. Do you want to change appliance type?",
      "title": "Changing appliance type"
    }
  },
  "noCountryProfile": "No country profile",
  "notFound": {
    "description": "Page you entered doesn't exist.",
    "title": "Not found"
  },
  "nutriUStory": {
    "accessories": {
      "error": "Select \"Appliance type\" and \"Product range\" first",
      "label": "Accessory type",
      "placeholder": "Accessory type"
    },
    "addContentToArticle": "Add {{ content }} to the article",
    "addedContentToArticle": "Added {{ content }} to the article ({{ currentValue }}/{{ maxValue }})",
    "category": {
      "errorMessage": "Article category is required",
      "label": "Category",
      "placeholder": "Choose article category"
    },
    "closeDialog": {
      "description": "You are about to leave this screen and discard the article information you entered. Do you want to save the article to continue editing later?",
      "title": "Save the article?"
    },
    "create": "Create article",
    "description": {
      "errorMessage": "Article description is required",
      "label": "Description",
      "placeholder": "Enter article description"
    },
    "details": "Article details",
    "devices": {
      "error": "Select \"Appliance type\" first",
      "label": "Product range",
      "placeholder": "Enter devices"
    },
    "edit": "Edit article",
    "errors": {
      "coverImage": {
        "required": "Article must have cover image."
      },
      "generic": {
        "description": "Error is displayed in the console. Please contact developers.",
        "title": "Error while saving the article"
      },
      "link": {
        "notValid": "URL is not valid.",
        "required": "Link is required.",
        "requiredInStory": "Article URL is required."
      },
      "linkedContent": {
        "requiredNumber": "Number of {{ content }} must be between {{ minContentNumber }} and {{ maxContentNumber }}."
      }
    },
    "id": "Article ID",
    "image": {
      "label": "Upload cover photo"
    },
    "link": {
      "placeholder": "Paste link here"
    },
    "placeholder": {
      "noRecipes": "Article does not have any recipes"
    },
    "steps": {
      "title": "Add steps"
    },
    "store": {
      "id": {
        "label": "Philips Store ID",
        "placeholder": "Enter Philips Store ID",
        "warning": "Make sure the Philips Store ID is correct and supported in the country it will be published in."
      },
      "title": "Store"
    },
    "subType": {
      "accessory": "Accessory",
      "cooking": "Cooking",
      "interview": "Interview",
      "link": "Link",
      "nutritionClaimArticle": "Nutrition claim article",
      "product": "Product",
      "store": "Store"
    },
    "title": {
      "errorMessage": "Article title is required",
      "placeholder": "Enter article title"
    }
  },
  "nutriUStroy": {
    "subType": {
      "healthArticle": "Health story"
    }
  },
  "nutrient": {
    "alcohol": "Alcohol",
    "calories": "Calories",
    "carbohydrates": "Carbohydrates",
    "cholesterol": "Cholesterol",
    "dietaryFibers": "Dietary fibers",
    "fat": "Fat",
    "omega3FattyAcids": "Omega-3 fatty acids",
    "organicAcids": "Organic acids",
    "protein": "Protein",
    "saturatedFat": "Saturated fat",
    "sugar": "Sugar",
    "unsaturatedFattyAcids": "Unsaturated fatty acids",
    "water": "Water"
  },
  "nutrientTable": {
    "amount": "Amount",
    "nrv": "% NRV*",
    "per100g": "Per 100 g",
    "per100ml": "Per 100 ml"
  },
  "nutrientUnits": {
    "gram": "g",
    "kcal": "kcal",
    "microgram": "μg",
    "miligram": "mg"
  },
  "nutritionClaim": {
    "plural": "Nutrition claims",
    "singular": "Nutrition claim"
  },
  "nutritionalClaimGuidline": {
    "defaultDescription": "Unknown nutritional claim type",
    "description": "{{ claimReference }} must contain {{ comparatorValue }} than {{ value }} of {{ nutrient }}",
    "less": "less",
    "more": "more",
    "percentageOdTotalCalories": "{{ nutrient }} must take up {{ comparatorValue }} than {{ value }} of the total amount of calories. 1 {{ unit }} of {{ nutrient }} is {{ nutrientKcalValue }} kcal.",
    "recommendedDailyIntake": "the recommended daily intake",
    "totalKcal": "total kcal"
  },
  "nutriuStory": {
    "applianceType": {
      "errorMessage": "Appliance type is required",
      "label": "Appliance type",
      "placeholder": "Choose appliance type"
    },
    "contentNotValid": {
      "maxLinkedContentReached": {
        "dialogDescription": "You can not add more {{ content }} to the article as you reached the maximum number of recipes ({{currentNumber}} of {{maximumNumber}}).",
        "dialogTitle": "Maximum number reached"
      }
    },
    "linkedContentRemovalDescription": "After you remove this {{ content }}, it will not be visible in the search field until you save the article. Are you sure you want to remove the {{ content }}?",
    "linkedContentRemovalTitle": "Remove from the article",
    "noContentFound": "No {{ content }} found for",
    "noLinkedContentAddedToArticle": "There are no {{ linkedContent }} added to this article.",
    "recipeNotValid": {
      "translationNotApproved": {
        "title": "Recipe is not valid for the article"
      },
      "wrongAuthorType": {
        "description": "Recipe author must be Philips"
      }
    },
    "searchAddedContent": "Search added {{ content }}",
    "searchContent": "Search {{ content }}",
    "storeProductId": {
      "errorMessage": "Philips Store ID is required"
    }
  },
  "nutriustory": {
    "selectLanguageApplianceFirst": "Please choose language and appliance type first."
  },
  "onboardingAnnouncement": {
    "browse": {
      "title": "Browse onboarding announcements"
    }
  },
  "pagination": {
    "next": "Next",
    "previous": "Previous"
  },
  "pan": {
    "combined": "Combined",
    "left": "Left pan",
    "right": "Right pan",
    "xxlPan": "XXL Pan"
  },
  "pinnedCollection": {
    "activation": {
      "contentWillBeDeactivated": "<div>You are activating pinned recipe book for {{ country }}, therefore, {{ collectionBundlesForDeactivation }} will be deactivated.</div>",
      "missingPublishedRecipeBook": "Linked recipe book must have equal appliances, be in live status and published in this country."
    },
    "addBasicInformation": "Add basic information",
    "details": "Pinned recipe book details",
    "enterTitle": "Pinned recipe book title",
    "pinCollection": "Pin a recipe book",
    "saveThePinnedCollection": "Save the pinned recipe book?",
    "title": "Pinned recipe book title",
    "toastMessage": {
      "saved": "Pinned recipe book saved"
    },
    "validation": {
      "notSuitableForCountry": "Pinned recipe book is not published to the country"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the pinned recipe book information you entered. Do you want to save the pinned recipe book to continue editing later?"
  },
  "pinningModal": {
    "description": "Select countries where you want to pin this {{ contentType }} in. You can only select countries, where this {{ contentType }} is already published. Only one {{ contentType }} can be pinned in a country at a time. Conflicts will be listed under the dropdown field.",
    "selectPlaceholder": "Choose countries",
    "title": "Changing pinned status of {{ contentType }}",
    "conflicts": {
      "description": "You have selected countries that already contain a pinned {{ contentType }}. If you proceed with the pinning action of the current {{ contentType }}, already pinned {{ contentType }} will be unpinned first in each selected country with a conflict.",
      "title": "There are pinned publication conflicts with selected countries."
    }
  },
  "placeholder": {
    "add": {
      "buttonText": "Add button text",
      "description": "Add description",
      "title": "Add title"
    },
    "choose": {
      "filter": "Choose {{ filter }}",
      "measurement": "Choose measurement"
    },
    "enter": {
      "amount": "Enter amount",
      "collectionName": "Enter recipe book name",
      "ingredientName": "Enter ingredient name",
      "ingredientNamePlural": "Enter ingredient name plural",
      "ingredientNameSingular": "Enter ingredient name singular",
      "measurement": "Enter measurement",
      "minutes": "Enter minutes",
      "seconds": "Enter seconds"
    }
  },
  "plannedMessages": {
    "addRulesetModal": {
      "formType": {
        "label": "Form type",
        "title": "Choose a type of form you want to create"
      },
      "reducer": {
        "label": "Operator",
        "title": "Choose how you want to connect the rules"
      },
      "required": "All fields must be selected.",
      "rule": {
        "label": "Rule selection",
        "placeholder": "Choose a rule"
      },
      "rulesetName": {
        "label": "Ruleset name",
        "placeholder": "Enter ruleset name"
      }
    },
    "createPlan": "Create a message plan",
    "editPlan": "Edit the message plan",
    "enterPlanTitle": "Enter plan title",
    "message": {
      "addNew": "Add new message",
      "label": "Message",
      "linkToArticleLabel": "Link to article",
      "locationType": "Location type",
      "messageWillBeLostWarning": "The delivery type is not selected. This message will be lost once the browser tab is closed. ",
      "placeholder": "Enter message here"
    },
    "messageGroup": {
      "addNew": "Add new message group",
      "label": "Message group"
    },
    "noRulesetApplied": {
      "add": "Add a rule/ruleset",
      "description": "There is no ruleset applied to this group",
      "title": "No ruleset applied"
    },
    "note": {
      "label": "Note",
      "placeholder": "Enter note"
    },
    "plan": {
      "activateModal": {
        "content": "Are you sure you want to change this plan's status?",
        "title": "Change plan status"
      },
      "button": {
        "activatePlan": "Activate plan",
        "deactivatePlan": "Deactivate plan"
      },
      "deleteModal": {
        "content": "Are you sure you want to delete this plan?",
        "title": "Delete plan"
      },
      "messageSection": {
        "title": "Message plan details"
      },
      "noPlans": "There are no plans",
      "note": {
        "label": "Note"
      },
      "planId": {
        "label": "Message plan ID"
      },
      "status": {
        "active": "Active",
        "inactive": "Inactive",
        "label": "Status"
      },
      "view": {
        "rulesetsTitle": "Rulesets & message groups"
      }
    },
    "planAction": {
      "addNew": "Add new message group rule",
      "category": {
        "label": "Category",
        "notFound": "Action category not found",
        "placeholder": "Choose a category",
        "required": "Action category is required"
      },
      "deleteModal": {
        "content": "Deleting this message group rule will remove all the nested messages group rules and their messages. Are you sure you want to delete it?",
        "title": "Delete message group rule"
      },
      "label": "Message group rule",
      "name": {
        "placeholder": "Enter name",
        "required": "Action name is required."
      }
    },
    "planStepMessage": {
      "activateModal": {
        "content": "Are you sure you want to change this message's status?"
      },
      "deleteModal": {
        "content": "Are you sure you want to delete this message?",
        "title": "Delete message"
      },
      "statusModal": {
        "title": "Change message status"
      }
    },
    "rule": {
      "label": "Rule"
    },
    "ruleset": {
      "addNewRuleToRuleset": "Add a rule/ruleset",
      "collapse": "Collapse ruleset",
      "expand": "Expand ruleset",
      "label": "Ruleset",
      "removeRule": {
        "description": "Are you sure you want to remove the rule?",
        "title": "Rule removing"
      },
      "removeRuleset": {
        "description": "Are you sure you want to remove the ruleset?",
        "title": "Ruleset removing"
      }
    },
    "tableHeaderTitle": "Name",
    "validation": {
      "title": {
        "required": "Plan title is required"
      }
    }
  },
  "preparedMeal": {
    "action": {
      "deflag": "Deflag image",
      "delete": "Delete image"
    },
    "changeStatus": {
      "deflag": {
        "content": "You are about to deflag this recipe image. Please enter your reason:",
        "title": "Deflagging image"
      }
    },
    "deflaggedToastMessage": "Image successfully deflagged.",
    "delete": {
      "content": "You are about to delete this recipe image. Please enter your reason:",
      "title": "Deleting image"
    },
    "inRecipe": "In recipe",
    "title": "Image from {{ owner }}"
  },
  "processingStep": {
    "addNewStep": "Add new step",
    "addStep": "Add step",
    "durationWarning": "Processing time must be between {{ min }} and {{ max }}",
    "editStep": "Edit step",
    "enterStepDescription": "Enter step description",
    "enterStepTitle": "Enter step title",
    "errorMessage": {
      "description": "All steps must have a description",
      "title": "Step must have a title"
    },
    "header": {
      "description": "Description",
      "photo": "Photo",
      "stepType": "Step type",
      "time": "Time",
      "title": "Step title",
      "video": "DAM Video"
    },
    "processingTime": "Processing time",
    "saveStep": "Save step",
    "step": "Step {{stepNumber}}",
    "stepDescription": "Step description",
    "stepTypePlaceholder": "Choose step type",
    "temperatureWarning": "Temperature must be between {{ min }}°C and {{ max }}°C",
    "uploadStepImage": "Upload step image"
  },
  "product": {
    "accessoryAvailabilityLabel": "Accessory is available in the same countries as the device they’re linked to.",
    "articles": "Product articles",
    "faqDialogDescription": "Product FAQ URLs may vary based on country, language, and product variant. Here is a list of all possible country language variant combinations and their FAQ, for all countries where the product is activated.",
    "linkedProducts": "Linked products",
    "manualsDialogDescription": "Product manual URLs may vary based on country and language. Here is a list of all possible country language combinations and their product manual URLs, for all countries where the product is activated.",
    "model": "Model",
    "noArticles": "Product is not used in any article.",
    "noFaqFound": "No FAQs were found for the device as the device is not active in any country.",
    "noLinkedProducts": "Product has no linked products.",
    "noManualsFound": "No manuals were found for the device as the device is not active in any country.",
    "noRecipes": "Product is not used in any recipe.",
    "noVariants": "Product has no variants.",
    "notAvailableInAnyCountry": "Product is not available in any country.",
    "range": "Range",
    "recipes": "Product recipes",
    "translations": "Product translations",
    "variants": "Product variants",
    "variantsAndAvailabilities": "Product variants and availabilities",
    "errors": {
      "noVariants": "At least one Product variant is required."
    },
    "properties": {
      "add": "Add product property",
      "empty": "There are no product properties.",
      "title": "Product properties"
    },
    "brandNotDefined": "Product brands are not defined for: {{ countries }}. Without defined product brand for a country, device cannot be activated in that country.",
    "brands": "Product brands"
  },
  "publicationModal": {
    "alreadyActivatedInCountry": "Already activated in country.",
    "alreadyPublishedInCountry": "Already published in country.",
    "anotherPushMessageAlreadyScheduledInTimeframe": "Push message {{ scheduledEvents }} is already scheduled in the timeframe of {{ timeframe }} hours before or after the event.",
    "anotherPushMessageAlreadySentInTimeframe": "Push message {{ pushMessages }} was already sent in the last {{ timeframe }} hours.",
    "approvedTranslationsLabel": "Approved translations: {{ languages }}.",
    "contentAlreadyScheduled": "Content is already scheduled.",
    "contentNotManaged": "Scheduling is not allowed because linked content belongs to the consumer. Only content managed by Philips can be scheduled.",
    "countryHasActivePackHolder": "Already activated {{ activePackName }} will be automatically deactivated in this country.",
    "linkedContentNotPublished": "Linked content is not published to country.",
    "linkedContentNotScheduledOrPublished": "Linked content is not published or scheduled for publishing. Linked content should be published or scheduled before publishing.",
    "linkedProfileDoesNotBelongToCountry": "Linked profile does not belong to country.",
    "missingApprovedTranslations": "Translation for {{ country }} is not approved or does not exist.",
    "missingApprovedTranslationsLabel": "Unapproved or missing translations: {{ languages }}.",
    "missingIngredientTranslation": "Some ingredients are missing translations: {{ missingTranslations }}",
    "missingOneApprovedTranslation": "Missing at least one approved translation.",
    "missingPublishedRecipes": "{{ publishedRecipes }}/{{ totalRecipes }} recipes have been published in the country. Recipes that are not published will not be visible.",
    "missingRecipeLiveTranslationsForCountry": "One or more linked recipes are missing translations.",
    "noneOfDevicesSupportVoltage": "None of the devices from the recipe support {{ electricSystem }} electric system.",
    "notLive": "Content is not in live status.",
    "pushMessageAlreadySent": "Push notification has already been sent.",
    "recipeWithSameTitleAlreadyPublishedInCountry": "Recipes with the same name already exist in the country: {{ shortContentId }}",
    "countryAlreadyHasActiveEasyRecipeBook": "Already activated {{ activePackName }} will be automatically deactivated in this country.",
    "countryHasActiveCampaign": "Already activated {{ activeCampaignName }} will be automatically deactivated in this country.",
    "missingPublishedHighlights": "At least {{ minHighlights }} highlights must be published to the country."
  },
  "publishContentDialog": {
    "subtitle": "Select a country profile to which you wish to publish the selected content list. Please enter your reason:"
  },
  "pushMessage": {
    "appliance": {
      "required": "Appliance is required"
    },
    "deliveries": {
      "delivered": {
        "label": "Sent"
      },
      "error": {
        "label": "Failed"
      },
      "pending": {
        "label": "Pending"
      },
      "sentLanguages": "Sent {{ sentNumber }}/{{ totalNumber }} languages",
      "skipped": {
        "label": "Skipped"
      }
    },
    "description": {
      "placeholder": "Enter push notification description",
      "required": "Push notification description is required"
    },
    "details": "Push notification details",
    "errors": {
      "delete": {
        "title": "Error while deleting the push notification"
      },
      "generic": {
        "description": "Error is displayed in the console. Please contact developers.",
        "title": "Error while saving the push notification"
      },
      "send": {
        "title": "Error while sending the push notification"
      }
    },
    "form": {
      "create": "Create push notification",
      "edit": "Edit push notification"
    },
    "saveTheAnnouncement": "Save the announcement?",
    "saveThePushMessage": "Save the push notification?",
    "section": {
      "content": {
        "title": "Add push notification content"
      }
    },
    "sendModal": {
      "description": "You will send a push notification to {{ appliance }} in {{ countries }}. Please, take into account that it is possible that push notification will not be sent immediately due to technical limitation.",
      "title": "Send push notification?"
    },
    "title": {
      "placeholder": "Enter push notification title",
      "required": "Push notification title is required"
    },
    "toastMessage": {
      "delete": "The push notification has been deleted",
      "saved": "Push notification saved"
    },
    "translationErrors": {
      "descriptionRequired": "Push notification description is required",
      "titleRequired": "Push notification title is required"
    },
    "type": {
      "announcement": "Announcement",
      "article": "Article",
      "general": "General",
      "label": "Type",
      "placeholder": "Choose type",
      "recipe": "Recipe",
      "recipeBook": "Recipe book",
      "required": "Type is required"
    },
    "view": "View push notification",
    "wantToSaveBeforeLeaving": "Do you want to save the changes before leaving?"
  },
  "pushMessages": {
    "browse": {
      "title": "Browse push notifications"
    }
  },
  "qualityIndicator": {
    "calorieDifference": "There is a significant difference in kcal values between the databases. Please check.",
    "fat": "fat",
    "group": {
      "fatProteinSodium": "This recipe is high in {{ indicatorsList }}. Please check."
    },
    "hover": {
      "fat": "The amount of fat is higher than {{ limit }} per serving",
      "protein": "The amount of protein is higher than {{ limit }} per serving",
      "sodium": "The amount of sodium is higher than {{ limit }} per serving"
    },
    "protein": "protein",
    "sodium": "sodium"
  },
  "recipe": {
    "activePreparation": {
      "errorMessage": "Active preparation time is required",
      "label": "Active preparation",
      "placeholder": "Enter active preparation time",
      "positiveNumberErrorMessage": "Preparation time must be a positive number.",
      "tooltip": "Time spent washing, slicing, and dicing ingredients"
    },
    "activeTime": {
      "label": "Active time",
      "tooltip": "Processing time + Active preparation"
    },
    "addToCollection": {
      "content": "A recipe can only be added to the DRAFT and APPROVED recipe books",
      "recipeAlreadyInCollection": "Recipe is already in recipe book \"{{ collectionName }}\"",
      "saveButtonLabel": "Add recipe",
      "successMessage": "Recipe added to recipe book \"{{ collectionName }}\"",
      "title": "Add recipe “{{ title }}” to a recipe book"
    },
    "blenderTotalTime": {
      "tooltip": "Active + Passive preparation"
    },
    "chageDomainToGlobal": {
      "dialog": {
        "descriptionUs": "Source language translation will be changed to English. Please, correct translations if needed.",
        "saveButton": "Yes, move to GLOBAL",
        "title": "Are you sure you want to change your local domain to GLOBAL?"
      },
      "toastMessage": "Recipe domain successfully changed to GLOBAL"
    },
    "changeOrganizationConfirmation": {
      "description": "Ingredient amounts and recipe step source product temperatures will be reset. Are you sure?",
      "title": "Change organization"
    },
    "changeStatus": {
      "delete": {
        "content": "Please select the reason (the reason will be visible to consumer):",
        "title": "Recipe deletion"
      }
    },
    "cookTime": {
      "errorMessage": "Total processing time cannot be greater than {{ minutes }} minutes"
    },
    "create": "Create {{device}} recipe",
    "details": "Recipe details",
    "downloadSuccessMessage": "The recipe is downloaded.",
    "edit": "Edit {{device}} recipe",
    "errorMessage": {
      "numberOfServings": {
        "max": "Number of servings must be between {{ min }} and {{ max }}",
        "min": "Number of servings must be greater than {{ min }}"
      }
    },
    "errors": {
      "coverImage": {
        "required": "Recipe must have cover image."
      },
      "imageRequired": "Image is required"
    },
    "export": "Recipe export",
    "exportRecipe": "Export recipe",
    "form": {
      "create": "Create recipe",
      "edit": "Edit recipe"
    },
    "id": "Recipe ID",
    "import": "Import recipe",
    "importDescription": "Click select file or drag and drop your ZIP file",
    "ingredient": {
      "delete": {
        "content": "Are you sure you want to delete the ingredient?",
        "title": "Delete ingredient"
      }
    },
    "ingredientPriority": {
      "main": "Main",
      "regular": "Regular"
    },
    "ingredients": {
      "migration": {
        "button": "Migrate ingredients",
        "cancelModal": {
          "description": "Are you sure you wish to discard the ingredient migration?",
          "title": "Cancel ingredient migration"
        },
        "confirmationModal": {
          "description": "Do you wish to confirm the migration of ingredients to steps?<br/><br/>Please note that after this migration, undo will not be possible. Ingredient editing will be available through the steps section. Any ingredients not assigned to a step when the recipe is saved will be deleted.",
          "title": "Confirm ingredient migration"
        },
        "message": "You need to migrate all ingredients to recipe steps section to enable editing.",
        "modal": {
          "subtitle": "Please assign the ingredients to the corresponding steps",
          "title": "Ingredients migration",
          "unitMismatchNotice": "Highlighted ingredients will not be migrated due to unit incompatibilities. The same ingredients with differing unit values will need to be added manually after the migration."
        },
        "nonMigratedIngredients": {
          "description": "Not all ingredients from your recipe have been fully migrated to the steps. Ingredients that remain unmigrated will be permanently deleted from the recipe if you proceed.<br/><br/>Do you want to proceed with saving the changes?",
          "title": "Some ingredients have not been migrated."
        },
        "suggestions": {
          "message": "We have attempted to automatically assign steps to ingredients based on their mention in the step descriptions. Please review the result and adjust accordingly before confirming the migration.",
          "title": "Auto-assigned steps"
        },
        "title": "Update is required"
      }
    },
    "maxNumberOfServings": {
      "label": "Maximum servings"
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the recipe now?"
    },
    "missingIncompatibleDevicesDialog": {
      "description": "The recipe does not have {{ deviceNames }} in the product range. To display a device incompatibility message on a mobile device please add the device to the incompatible devices field.",
      "proceedLabel": "Ignore warning and save",
      "title": "Incompatible device detected"
    },
    "noIngredients": "Recipe doesn't have any ingredients.\n",
    "noProcessingSteps": "Recipe doesn't have any steps.",
    "noTags": "Recipe doesn't have any tags.",
    "numberOfServings": {
      "label": "Number of Servings",
      "placeholder": "Enter number of servings"
    },
    "nutriInfoCalculation": {
      "beingRecalculated": "Nutritional information is currently being recalculated.",
      "canNotBeCalculated": "Main ingredients can't be calculated. Please check if any data mappings or conversions are missing.",
      "cannotBeDone": "Nutritional information cannot be calculated due to missing information.",
      "inAFewMinutes": "Nutritional information will be available in a few minutes.",
      "pleaseCheck": "Please check if any data mappings or conversions are missing.",
      "willBeAvailableSoon": "Information will be available soon."
    },
    "nutriUStory": {
      "notUsed": "Recipe is not being used in any article."
    },
    "nutritionalInformation": {
      "breakdown": {
        "caloriesBreakdownGraphTitle": "Calories breakdown",
        "hide": "Hide breakdown",
        "show": "Show breakdown"
      },
      "countrySelectionLabel": "Display nutritional information for",
      "disclaimer": {
        "amountPerServing": "Amounts are per serving. <strong>One serving is 1/{{ numberOfServings }} of “{{ recipeTitle }}” recipe</strong>.",
        "dailyValues": "* Nutrient reference value is based on a {{ baseCalories }} kcal diet. Your daily values may be higher or lower depending on your calorie needs.",
        "numerOfServings": "Each serving is<br>1/{{ numberOfServings }} recipe"
      },
      "kcal": "kcal",
      "perServing": "Per serving",
      "sectionTitle": "Nutritional information",
      "tab": {
        "energy": "Energy",
        "nutrients": "Nutrients"
      }
    },
    "passivePreparation": {
      "label": "Passive preparation",
      "placeholder": "Enter passive preparation time",
      "tooltip": "Time spent drying, soaking or freezing ingredients"
    },
    "preparedMeals": {
      "emptyState": "There are no images for selected country",
      "sectionTitle": "Recipe images by consumers"
    },
    "print": "Print",
    "processingTimeWithPressure": {
      "tooltip": "Includes build and release pressure time"
    },
    "reassignLanguage": {
      "description": "Choose a supported language of the recipe’s country.",
      "toastMessage": "Recipe language was changed to {{ language }}",
      "tooltip": "To reassign recipe language recipe must have more than one approved translation."
    },
    "recipePreparations": {
      "emptyState": "There are no images for this recipe",
      "hideButton": "Hide images",
      "showButton": "Show images",
      "title": "Recipe images by consumers"
    },
    "similarIngredientsDialog": {
      "description": "Looks like the recipe you want to save has very similar ingredients as the following recipes:",
      "title": "Ingredient combination detected"
    },
    "step": {
      "delete": {
        "content": "Are you sure you want to delete the step?",
        "title": "Delete step"
      }
    },
    "tag": {
      "difficulty": {
        "easyToStart": "Yes",
        "label": "Easy to start",
        "notEasyToStart": "No",
        "placeholder": "Easy to start"
      }
    },
    "titleConflict": {
      "explanation": "Please make sure you are not creating a duplicate recipe, as there is already one existing using the same name",
      "inLanguage": "in language"
    },
    "toastMessage": {
      "saved": "Recipe has been successfully saved",
      "translationSaved": "Recipe translation saved successfully."
    },
    "totalTime": {
      "errorMessage": "Total time must be greater than 0",
      "label": "Total time",
      "tooltip": "Processing time + Active preparation + Passive preparation"
    },
    "translations": {
      "export": {
        "includeNonTranslatable": "Include not translatable ingredients",
        "noCommonLanguages": "Selected items don't have common languages."
      }
    },
    "unitSystem": {
      "label": "Unit system"
    },
    "view": "View recipe",
    "exportRecipeTooltip": "Export and download recipe in the .XML format",
    "moveToGlobal": "Change domain of a recipe to GLOBAL",
    "printOrSaveAsPdf": "Print or download recipe as .PDF file"
  },
  "recipeBookCarousel": {
    "addCollectionsToCarousel": "Add recipe books to the carousel (min.{{ minCollections }})",
    "addedCollectionsToCarousel": "Added recipe books to the carousel ({{ currentValue }})",
    "collections": {
      "emptyStateMessage": "There are no recipe books in this carousel."
    },
    "details": "Recipe book carousel details",
    "enterTitle": "Recipe book carousel title",
    "noRecipeBooksAddedToCarousel": "There are no recipe books added to this carousel.",
    "recipeBookRemovalDescription": "Are you sure you want to remove the recipe book from the carousel?",
    "recipeBookRemovalTitle": "Remove from carousel",
    "saveTheRecipeBookCarousel": "Do you want to save the changes before leaving?",
    "title": "Recipe book carousel title",
    "toastMessage": {
      "saved": "Recipe book carousel saved"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe book carousel information you entered. Do you want to save the recipe book carousel to continue editing later?"
  },
  "recipeCarousel": {
    "details": "Recipe carousel details",
    "enterTitle": "Recipe carousel title",
    "pinCollection": "Add a recipe book to carousel",
    "recipeBookAddTitle": "Add recipe books to the carousel",
    "recipeBookRemovalTitle": "Remove from carousel",
    "saveTheRecipeCarousel": "Save the recipe carousel?",
    "title": "Recipe carousel title",
    "toastMessage": {
      "saved": "Recipe carousel saved"
    },
    "wantToSaveBeforeLeaving": "You are about to leave this screen and discard the recipe carousel information you entered. Do you want to save the recipe carousel to continue editing later?"
  },
  "recipeDetails": {
    "commentsByConsumers": "Comments by consumers"
  },
  "recipeForm": {
    "coverImage": {
      "label": "Upload cover photo"
    },
    "nutritionalInformation": {
      "label": "Note",
      "placeholder": "Enter note"
    },
    "recipeType": {
      "ingredient": "Ingredient",
      "label": "Recipe type",
      "regular": "Regular"
    },
    "subtitle": {
      "basicInformation": "Add basic information",
      "ingredient": "Add ingredient",
      "nutritionalInformation": "Add nutritional information",
      "recipeType": "Recipe type",
      "servings": "Servings",
      "timeAndServings": "Time and servings"
    }
  },
  "recipeLock": {
    "title": "Another user is editing this recipe",
    "warning": "Recipe can't be edited. User {{ user }} is currently editing this recipe."
  },
  "recipePreparation": {
    "action": {
      "deflag": "Deflag image"
    },
    "basicInformation": {
      "device": "Device",
      "inRecipe": "In recipe"
    },
    "title": "Image from {{ owner }}"
  },
  "recipeRepublish": {
    "missingApprovedTranslations": "Translation for {{label}} profile is not approved or does not exist.\n",
    "warningMessage": {
      "published": "This recipe is already published to this Country Profile. Do you want to republish it as a new recipe?",
      "unpublished": "This recipe was already published to this Country Profile. Do you want to publish it as a new recipe or republish it to its previous position?"
    }
  },
  "recipeStep": {
    "appliance": {
      "label": "Appliance",
      "placeholder": "Select appliance group",
      "required": "Please select the Domain first"
    },
    "device": {
      "label": "Source product",
      "placeholder": "Select source product"
    },
    "duration": {
      "label": "Duration",
      "placeholder": "0"
    },
    "errors": {
      "couldNotLoadParameters": "Could not load step parameters",
      "missingTranslation": "Missing recipe step translation"
    },
    "numberOfShakes": {
      "placeholder": "0"
    },
    "preparationStep": {
      "label": "Preparation"
    },
    "processingStep": {
      "label": "Processing"
    },
    "propagateChangesDialog": {
      "description": "Changes of source products and product ranges will be applied to all other processing steps",
      "title": "Save step"
    },
    "speed": {
      "label": "Speed",
      "placeholder": "0"
    },
    "temperature": {
      "label": "Temperature",
      "placeholder": "0"
    },
    "tools": {
      "placeholder": "Select tools"
    }
  },
  "recipeTranslations": {
    "clickSelectFileOrDragXmlOrZip": "Click select file or drag and drop your {{ fileTypes }} files",
    "keywords": {
      "label": "Keywords",
      "placeholder": "Add keywords"
    }
  },
  "recipeValidator": {
    "ingredientsBelowLimit": "Recipe has more than {{limit}} ingredients",
    "processingStep": {
      "preheatingOrderErrorMessage": "Please move the processing step with Preheat cooking method at least one place before the earliest processing step.",
      "preheatingOrderErrorTitle": "Error in recipe steps"
    },
    "requiredIngredient": "Recipe must have at least one ingredient",
    "requiredStep": "Recipe must have at least one step"
  },
  "recipes": {
    "exportTranslations": "Export translations"
  },
  "reloadPageDialog": {
    "button": {
      "proceed": "Refresh",
      "stop": "Ignore for 5 minutes"
    },
    "description": "The new version of the Admin UI application has been released. Please refresh the page to prevent unexpected issues.",
    "title": "New version available"
  },
  "reportReason": {
    "inappropriateLanguage": "Inappropriate language",
    "inappropriateOrAbusiveContent": "Inappropriate or abusive content",
    "incompleteContent": "Incomplete content",
    "incorrectContent": "Incorrect content",
    "other": "Other",
    "spam": "Spam"
  },
  "reportedContent": {
    "dialog": {
      "subtitle": "Please enter additional reason (this won't be visible to the consumer)",
      "takingOfflineReason": "Taking offline reason",
      "takingOfflineSubtitle": "Please enter additional reason"
    },
    "statusIndicatorLabel": "Unresolved reports"
  },
  "reportedContentStatus": {
    "inReview": "In review",
    "reported": "Reported",
    "reviewed": "Reviewed"
  },
  "reportedItems": {
    "searchPlaceholder": "Find the reported item by text or short ID"
  },
  "reportingInformation": {
    "authorType": {
      "philips": "Philips"
    },
    "commentedBy": "Commented by",
    "noReports": "No reports",
    "products": "consumer source pro.",
    "reason": "Reason",
    "reportReason": "Report reason",
    "reportStatus": "Report status",
    "reportedAt": "Reported at",
    "reportedBy": "Reported by",
    "title": "Reporting information",
    "toastMessage": {
      "copyIDSuccess": "User ID copied to clipboard"
    }
  },
  "republishPosition": {
    "new": "New {{ contentType }}",
    "previous": "Previous position",
    "top": "New recipe"
  },
  "resourceType": {
    "collection": "Recipe book",
    "ingredient": "Ingredient",
    "recipe": "Recipe",
    "tip": "Article"
  },
  "richItemSelector": {
    "emptyStateLabel": "There are no items added.",
    "fetchDisabledLabel": "Please fill required information first",
    "itemRemovalDescription": "Are you sure you want to remove the item from the selected items?",
    "itemRemovalTitle": "Remove item",
    "noSearchResultsLabel": "No items found for",
    "searchInputPlaceholder": "Search items",
    "searchItemsTitle": "Add items",
    "selectedItemsSearchBarInputPlaceholder": "Search added items",
    "selectedItemsTitle": "Selected items ({{ selectedItemsCount }})"
  },
  "role": {
    "chef": "Chef",
    "communityManager": "Community manager",
    "contentManager": "Content manager",
    "globalContentManager": "Global content manager",
    "ingredientManager": "Ingredient manager",
    "nutritionist": "Nutritionist",
    "organization": {
      "errors": {
        "required": "Domain is required"
      }
    },
    "readOnlyAccess": "Read only access",
    "systemAdministrator": "System administrator"
  },
  "saveSearch": {
    "placeholder": "Enter search title",
    "title": "Enter the name of the saved search"
  },
  "savedFilters": {
    "clearFilters": "Clear filters",
    "selecteFilterSet": "Select saved filter set",
    "youDontHaveAnySets": "You don't have any saved filter sets",
    "youDontHaveAnySetsForDomain": "You don't have any saved filter sets for this domain"
  },
  "scheduledEvent": {
    "content": {
      "placeholder": "Search content or push notification"
    },
    "enterEventName": "Enter event name",
    "errors": {
      "eventName": {
        "required": "Event name is required"
      },
      "saving": "Scheduled event could not be saved",
      "scheduledAt": {
        "pastDate": "Publish at date must be in the future",
        "required": "Publish at date is required"
      },
      "scheduledEventType": {
        "required": "Type is required"
      }
    },
    "name": "Event name",
    "publishAt": "Publish at"
  },
  "scheduledEventType": {
    "maintenance": {
      "plural": "Maintenances",
      "singular": "Maintenance"
    }
  },
  "searchBar": {
    "deleteCustomFilterTitle": "Do you want to delete filter set \"{{ filterName }}\"?",
    "input": {
      "commentPlaceholder": "Search by comment text or content ID",
      "defaultPlaceholder": "What are you looking for?"
    }
  },
  "searchbar": {
    "clearFilters": "Clear",
    "saveSet": "Save set",
    "tab": {
      "all": "All",
      "allContent": "All content",
      "allCountries": "All countries",
      "allDomains": "All domains",
      "collections": "Recipe books",
      "comments": "Comments",
      "consumers": "Consumers",
      "nutriUStories": "Articles",
      "preparedMeal": "Consumer images",
      "recipes": "Recipes"
    }
  },
  "selectLabel": {
    "self": "{{label}} (you)"
  },
  "sensitiveFieldChecks": {
    "announcement": {
      "translation": {
        "callToAction": "Changed announcement button text",
        "description": "Changed announcement description",
        "title": "Changed announcement title"
      }
    },
    "article": {
      "step": {
        "translation": {
          "description": "Changed article step description",
          "title": "Changed article step title"
        }
      },
      "steps": {
        "added": "Added new article step"
      }
    },
    "collection": {
      "translation": {
        "description": "Changed recipe book description",
        "title": "Changed recipe book title"
      }
    },
    "modal": {
      "details": "All Approved translations will be moved to Draft status. Translations need to be updated due to the following content changes:",
      "title": "Translations moved to Draft"
    },
    "recipe": {
      "ingredients": {
        "added": "Added new recipe ingredient",
        "changed": "Changed recipe ingredient(s)"
      },
      "steps": {
        "added": "Added new step",
        "changed": "Changed step(s) description"
      },
      "translation": {
        "description": "Changed recipe description",
        "keywords": "Changed recipe keywords",
        "title": "Changed recipe title"
      }
    },
    "tip": {
      "processingStepTranslation": {
        "changedDescription": "Changed processing step(s) description",
        "changedTitle": "Changed processing step(s) title"
      },
      "processingSteps": {
        "added": "Added new processing step"
      },
      "translation": {
        "description": "Changed article description",
        "title": "Changed article title"
      }
    }
  },
  "sidebar": {
    "releaseNotes": "See what's new"
  },
  "ssoLandingPage": {
    "buttonText": "I want to login with my <strong>{{sso}}</strong> email",
    "cardTitle": "One step away from AdminUI!",
    "chooseProviderText": "Please choose the email domain you want to login with to proceed to AdminUI."
  },
  "status": {
    "approved": "Approved",
    "blocked": "Blocked",
    "deleted": "Deleted",
    "deletedByCM": "Deleted by Content Manager",
    "deletedByConsumer": "Deleted by consumer",
    "draft": "Draft",
    "inReview": "In review",
    "live": "Live",
    "liveFlagged": "Live flagged",
    "quarantined": "Quarantined"
  },
  "stepFormExpandedDialog": {
    "description": "Please close any steps that you are still editing before saving the recipe",
    "title": "Editing step"
  },
  "storeCategory": {
    "allProducts": "All products",
    "singleProduct": "Single product"
  },
  "story": {
    "toastMessage": {
      "saved": "Article saved",
      "translationSaved": "Article translation saved successfully"
    }
  },
  "table": {
    "header": {
      "action": "Action",
      "actions": "Actions",
      "announcementInfo": "Announcement info",
      "appliance": "Appliance",
      "articleInfo": "Article info",
      "category": "Category",
      "collectionInfo": "Recipe book info",
      "commentBy": "Comment by",
      "commentText": "Comment text",
      "contentInfo": "{{ contentType }} info",
      "countriesPublished": "Countries published",
      "dateAdded": "Date added",
      "dateCreated": "Date created",
      "datePosted": "Date posted",
      "domain": "Domain",
      "highlightInfo": "Highlight info",
      "info": "Info",
      "like": "Like",
      "likeAction": "Like",
      "modified": "Last modified",
      "numberOfLikes": "No. of Likes",
      "nutritionInfo": "Nutrition info",
      "published": "Published date",
      "recipeInfo": "Recipe info",
      "text": "Text",
      "title": "Title",
      "translations": "Translations",
      "type": "Type",
      "userType": "Owner type",
      "domainOrCountry": "Domain / Country"
    }
  },
  "tenantLogin": {
    "description": "Please select the application you want to continue to.",
    "title": "Start managing your content"
  },
  "timeRangeOptions": {
    "allTime": "All time",
    "lastDay": "Past 24 hours",
    "lastMonth": "Past month",
    "lastWeek": "Past week",
    "lastYear": "Past year"
  },
  "tip": {
    "accessoryType": "Accessory type",
    "errors": {
      "imageRequired": "Image is required"
    },
    "missingDialogOptions": {
      "question": "Do you want to edit the article now?"
    },
    "noProcessingSteps": "Article doesn't have any steps.",
    "titleConflict": {
      "explanation": "Please make sure you are not creating a duplicate article, as there is already one existing using the same name",
      "inLanguage": "in language"
    }
  },
  "titleConflict": {
    "explanation": "Please make sure you are not creating a duplicate {{ content }}, as there is already one existing using the same name",
    "inLanguage": "{{ content }} in language {{ language }}.",
    "severalLanguages": "{{ content }} in several languages."
  },
  "toast": {
    "translationDownload": "Translation download started"
  },
  "togglableSearchList": {
    "emptyStateLabel": "No items found",
    "itemRemovalDescription": "Are you sure you want to remove the item from the list?",
    "itemRemovalTitle": "Remove from list",
    "searchInputPlaceholder": "Search items"
  },
  "translations": {
    "aiTranslationJobCreated": "AI translation generation has been requested successfully.",
    "approveAll": {
      "dialogContent": "Are you sure you want to approve all translations?",
      "dialogTitle": "Approve all translations"
    },
    "approveValid": {
      "dialogContent": "Do you want to approve valid translations: {{ languages }}?",
      "dialogTitle": "Some translations are missing"
    },
    "changeStatus": {
      "dialogContent": "Are you sure you want to change translation status?",
      "dialogTitle": "Changing translation status"
    },
    "delete": {
      "dialogTitle": "Deleting translation"
    },
    "import": {
      "error": {
        "content": "content",
        "title": "Error while importing {{ contentType }}"
      },
      "success": "Translations imported"
    },
    "noViewPermission": "You have no permission to view translations.",
    "notAvailable": "No translations available",
    "reassignSourceLanguage": {
      "label": "Reassign source language\n",
      "success": "Source language successfully reassigned"
    },
    "status": {
      "approved": "Approved",
      "missing": "Missing",
      "unapproved": "Unapproved"
    },
    "statusChange": {
      "toastMessage": "Translation status changed successfully."
    }
  },
  "unit": {
    "hour": {
      "plural": "hours",
      "singular": "hour"
    },
    "kPa": "kPa",
    "minute": {
      "plural": "minutes",
      "singular": "minute"
    },
    "psi": "psi",
    "second": {
      "plural": "seconds"
    }
  },
  "unitSystem": {
    "imperial": "Imperial system",
    "metric": "Metric system",
    "usCustomary": "US Customary"
  },
  "unknownLanguage": "Unknown language",
  "unknownTranslationFor": {
    "ingredient": "Unknown translation for ingredient {{id}}"
  },
  "unofficial_allergen": {
    "added_sugars": "Added sugars",
    "alcohol": "Alcohol",
    "honey": "Honey",
    "meat": "Meat",
    "non_vegetarian": "Non-vegetarian",
    "pork": "Pork",
    "red_meat": "Red meat"
  },
  "user": {
    "assignedDomains": "Assigned domains",
    "assignedUserRoles": "Assigned user roles",
    "browse": {
      "title": "Browse users"
    },
    "company": {
      "label": "Company",
      "placeholder": "Enter company"
    },
    "deactivatedAt": "Deactivated at",
    "deactivationInformation": "Deactivation information",
    "details": "User details",
    "domainRelatedUserRoles": "Domain related user roles",
    "email": {
      "label": "Contact e-mail",
      "placeholder": "Enter contact e-mail"
    },
    "form": {
      "close": {
        "content": "You are about to leave this screen and discard the user information you entered. Do you want to save the user to continue editing later?",
        "title": "Save user?"
      },
      "create": "Create user",
      "domainSelection": "Domain selection",
      "edit": "Edit user",
      "placeholder": {
        "domains": "Assigned countries",
        "role": "Choose user role"
      }
    },
    "id": "User ID",
    "modifiedAt": "Last modified",
    "name": {
      "isRequired": "Name is required",
      "label": "Name",
      "placeholder": "Enter name"
    },
    "permissions": "Permissions",
    "registeredAt": "Registered at",
    "role": "Role",
    "saveSuccessful": "User has been successfully saved",
    "status": {
      "active": "Active",
      "deleted": "Deactivated",
      "inactive": "Deactivated"
    },
    "statusChangeDialog": {
      "deactivate": {
        "description": "User will be deactivated and won't be able to change or view content.",
        "title": "Are you sure you want to deactivate this user?"
      },
      "reactivate": {
        "title": "Reactivate this user?"
      }
    },
    "toastMessage": {
      "deactivated": "User deactivated",
      "reactivated": "User reactivated"
    },
    "user": "User",
    "userName": "User name",
    "userRole": "User role",
    "userRolesAndPermission": "Roles and permissions",
    "username": {
      "isRequired": "Username is required",
      "label": "Username",
      "placeholder": "Enter username"
    },
    "usernameExistsDialog": {
      "description": "An account with entered username already exists. View their user profile or change username.",
      "saveLabel": "View user",
      "title": "Username already exists"
    }
  },
  "users": {
    "searchPlaceholder": "Find user by name or email address"
  },
  "validation": {
    "generic": {
      "conditionIs": "When { property }} is {{ value }}",
      "conditionIsBetween": "When {{ property }} is between {{ first }} and {{ second }}",
      "conditionIsOver": "When {{ property }} is over {{ value }}",
      "conditionIsUnder": "When {{ property }} is under {{ value }}",
      "mustBe": "{{ property }} must be {{ value }}",
      "mustBeBetween": "{{ property }} must be between {{ first }} and {{ second }}"
    },
    "processingStep": {
      "required": "Recipe must have at least one processing step.",
      "requiredByAirfyer": "Processing steps must have temperatures and processing times specified."
    }
  },
  "validator": {
    "mustBeInteger": "Input can't be a decimal number."
  },
  "videoUrl": {
    "dam": {
      "label": "DAM Video link",
      "placeholder": "Paste video link from DAM"
    },
    "errors": {
      "fileTooLarge": "Maximum video size is {{ maxSizeInMb }} MB",
      "invalidProtocol": "Link can only accept URL address that starts with \"https\".",
      "noVideoContent": "This link does not contain any video.",
      "notValid": "URL is not valid."
    },
    "pal": {
      "label": "PAL Video",
      "placeholder": "Paste video link from PAL"
    },
    "placeholder": "Paste video link"
  },
  "viewType": {
    "social": "Social",
    "technical": "Technical"
  },
  "vitamin": {
    "biotin": "Biotin",
    "folicAcid": "Folic Acid",
    "niacin": "Niacin",
    "pantothenicAcid": "Pantothenic acid",
    "riboflavin": "Riboflavin",
    "thiamin": "Thiamin",
    "title": "Vitamins",
    "vitaminA": "Vitamin A",
    "vitaminB12": "Vitamin B-12",
    "vitaminB6": "Vitamin B-6",
    "vitaminC": "Vitamin C",
    "vitaminD": "Vitamin D",
    "vitaminE": "Vitamin E",
    "vitaminK": "Vitamin K"
  },
  "warning": {
    "iedge": {
      "description": "It seems you are using Internet Explorer or Edge browser. Application might not behave as expected.\n\n<br><br>\n\nIf you experience any issues, please try using Google Chrome or Mozilla Firefox.",
      "title": "Internet Explorer / Edge"
    }
  },
  "accessory": {
    "type": "Accessory type"
  },
  "accessoryType": {
    "consumable": "Consumable",
    "kit": "Kit",
    "standalone": "Standalone"
  },
  "deviceProperty": {
    "invalidMaxInterval": "The max value must be in steps of the provided interval.",
    "invalidMinInterval": "The min value must be in steps of the provided interval.",
    "minLessThanOne": "Min value must be greater than 0.",
    "minMaxMismatch": "Min value must be less than max value."
  },
  "jobStatus": {
    "completed": "Translations are created successfully.",
    "completedWithError": "AI translation creation has been partially successful.",
    "failed": "AI translation creation has failed.",
    "importing": "Importing of translated translations is in progress.",
    "pending": "AI translation process has started, translations are being processed, and will soon be sent to the AWS translation service for translating.",
    "readyForImport": "Translation process has finished, and translations are being imported into the system.",
    "submitted": "Translations are exported and sent to the AWS translation service for translating."
  },
  "productBrandsDialog": {
    "description": "Assign product brands to countries where you want to activate the product. A product cannot be activated in a country unless a brand is assigned to it for that specific country.",
    "multipleCountriesWithSameBrand": "Multiple brands are selected for: {{ item }}. Each country can only be assigned to one brand.",
    "title": "Update product brands"
  },
  "contentContainerDeviceCategory": {
    "noResult": "No devices found for this category",
    "noResultForCategory": "There are no assigned devices for appliance: {{category}}"
  },
  "contentLock": {
    "errorDialog": {
      "description": "This {{ contentType }} is currently being edited by {{ name }} ({{ email }}). You cannot make changes until they are finished. Please try again later or contact the editor if urgent.",
      "title": "Editing restricted"
    },
    "warningMessage": "Editing is disabled as {{ name }} ({{ email }}) is currently editing this {{ contentType }} since {{ date }}."
  },
  "reportedContentHeader": {
    "numberOfActiveReports": "Number of active reports",
    "numberOfReports": "Number of reports"
  },
  "food": {
    "translation": {
      "areYouSureDeleteTranslation": "Are you sure you want to delete the food translation?",
      "deletion": "Food translation deletion"
    }
  },
  "bulkExportTranslationModal": {
    "onlyAiGenerated": {
      "label": "Which translations to include?"
    },
    "title": "Export content translations",
    "useDefaultTranslation": {
      "label": "Which translations do you want to export?"
    }
  }
}